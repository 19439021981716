const LocationReviewsData = [
    {
        "date": "2024-01-27",
        "review_rate": 5.0,
        "customer_name": "Katie Chen",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jasmine W.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.8,
        "customer_name": "Jennifer Thomas",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Ellen W.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Trevor Hall",
        "service_type": "Garage Door Repair",
        "tech_name": "Louis M.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-15",
        "review_rate": 5.0,
        "customer_name": "Julie Rodgers",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "April A.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-04",
        "review_rate": 5.0,
        "customer_name": "Christopher James",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "John M.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Andrew Dixon",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Ronald P.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-12",
        "review_rate": 5.0,
        "customer_name": "Micheal Carpenter",
        "service_type": "Garage Door Repair",
        "tech_name": "Amanda F.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-12",
        "review_rate": 5.0,
        "customer_name": "Steven Hobbs",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Donna L.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-11",
        "review_rate": 5.0,
        "customer_name": "Anthony Ortega",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Brittany D.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Bryan Collins",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Rhonda T.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-26",
        "review_rate": 5.0,
        "customer_name": "Adam Cohen",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Glenn C.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Ellen Macdonald",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kevin I.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Aaron Griffith",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Elizabeth L.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-08",
        "review_rate": 5.0,
        "customer_name": "Desiree Robinson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Traci K.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.8,
        "customer_name": "Emily Reid",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Daniel F.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-18",
        "review_rate": 5.0,
        "customer_name": "Sean Ramirez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Robert L.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Maria Miller",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Heather D.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-18",
        "review_rate": 5.0,
        "customer_name": "Tammie Smith",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Meghan L.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "John Aguilar",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jennifer Z.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Jessica Williams",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Tara N.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Justin Walker",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Michelle F.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.8,
        "customer_name": "Jodi Mcgee",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Charlene W.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Kelsey Choi",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Caitlyn J.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Spencer Herrera",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Michael A.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Christine Walker",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kelly W.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Diamond Walker",
        "service_type": "Garage Door Services",
        "tech_name": "Carl H.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Michele Mann",
        "service_type": "Garage Door Installation",
        "tech_name": "Steven M.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.8,
        "customer_name": "Ashley Hill",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Michael C.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Susan Novak",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Belinda F.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-07",
        "review_rate": 5.0,
        "customer_name": "Antonio Lane",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael M.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Margaret Gray",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Julia M.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Mrs. Deanna Maldonado",
        "service_type": "Garage Door Off Track",
        "tech_name": "Christopher S.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-06",
        "review_rate": 5.0,
        "customer_name": "Tina Mayer",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Derrick J.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "George Schaefer",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Laurie C.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Amber Jones",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Barbara L.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Schwartz",
        "service_type": "Garage Door Services",
        "tech_name": "John S.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Gabriel Beck",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Desiree C.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Nancy Kennedy",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kelsey R.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Colin Martinez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Eileen M.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.8,
        "customer_name": "Alejandra Mcintosh",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Marie G.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Sarah Scott",
        "service_type": "Garage Door Installation",
        "tech_name": "Alex B.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "William Morales",
        "service_type": "Garage Door Repair",
        "tech_name": "Clifford S.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Carol Lucas",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Edward R.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Kevin Murphy",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Carlos T.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.8,
        "customer_name": "Cheryl Floyd",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Susan M.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Karla Shaw",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Rachel F.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Ryan Perez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Brittany M.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Isaiah Thompson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Francisco F.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.8,
        "customer_name": "Todd Conway",
        "service_type": "Garage Door Insulation",
        "tech_name": "Scott K.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-16",
        "review_rate": 5.0,
        "customer_name": "Julia Schultz",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Mary M.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-26",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Simon",
        "service_type": "Garage Door Installation",
        "tech_name": "Isaiah B.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.8,
        "customer_name": "Whitney Blake",
        "service_type": "Garage Door Off Track",
        "tech_name": "John P.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Andrew Thomas",
        "service_type": "Garage Door Installation",
        "tech_name": "Eric L.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Bradley English",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "David S.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Lisa Waters",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Mary B.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-22",
        "review_rate": 5.0,
        "customer_name": "Jason Beard",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "David P.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Megan Mcmillan",
        "service_type": "Garage Door Installation",
        "tech_name": "Christopher B.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-14",
        "review_rate": 5.0,
        "customer_name": "Joel Estrada",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kimberly K.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Kimberly Scott MD",
        "service_type": "Garage Door Services",
        "tech_name": "Ana H.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-03",
        "review_rate": 5.0,
        "customer_name": "Samantha Boyd",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kevin W.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.8,
        "customer_name": "Christine Vaughn",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "David O.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-10",
        "review_rate": 5.0,
        "customer_name": "Gary Elliott",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Donald B.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-26",
        "review_rate": 5.0,
        "customer_name": "Tabitha Preston",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kristine M.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.8,
        "customer_name": "Caroline Lindsey",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Angela T.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Christian Monroe",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Justin L.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-13",
        "review_rate": 5.0,
        "customer_name": "William Houston",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Peter H.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.8,
        "customer_name": "Melissa Cooke",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Morgan M.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.8,
        "customer_name": "Jerry Herrera",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Victoria W.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.8,
        "customer_name": "Heather Malone",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jamie S.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-11",
        "review_rate": 5.0,
        "customer_name": "Lauren Davis",
        "service_type": "Garage Door Installation",
        "tech_name": "Christopher T.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-30",
        "review_rate": 5.0,
        "customer_name": "Leah Kelley",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Robert F.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Brown",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Gloria H.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Jeremy Holt",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Richard A.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.9,
        "customer_name": "Melissa Le",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Joseph J.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Melinda Navarro",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Hannah W.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-11",
        "review_rate": 5.0,
        "customer_name": "Deanna Kaiser",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Thomas K.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Michelle Lewis",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Barbara M.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-15",
        "review_rate": 5.0,
        "customer_name": "Dustin Bishop",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Beverly P.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Karen Roach",
        "service_type": "Garage Door Installation",
        "tech_name": "Alexis K.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Whitney Green",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Andrew O.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.8,
        "customer_name": "David Kirk",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Michael W.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "David Rice",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Maria M.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Derek Anderson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Arthur H.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-11",
        "review_rate": 5.0,
        "customer_name": "Jean Goodman",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Gabriel K.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Mallory Walker",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Tina F.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Mrs. Elizabeth Crosby",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Tanya H.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Timothy Ballard",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Juan W.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.8,
        "customer_name": "Zachary Roth",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Matthew B.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-17",
        "review_rate": 5.0,
        "customer_name": "Tanya Dean",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Trevor A.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Anita Alvarado",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Alexandra J.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.8,
        "customer_name": "Mark Huerta",
        "service_type": "Garage Door Services",
        "tech_name": "Julian E.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Robin Morris",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Lisa M.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.9,
        "customer_name": "Brooke Tucker",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Samuel K.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Joshua Spence",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Maria S.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-20",
        "review_rate": 5.0,
        "customer_name": "David Martin",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kristen H.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.8,
        "customer_name": "Daniel Torres",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Emily F.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Jesus Alvarez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Crystal C.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.8,
        "customer_name": "Dan Jones",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Cindy W.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Michael Hunter",
        "service_type": "Custom Garage Door Design",
        "tech_name": "David C.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Sandra Melton",
        "service_type": "Garage Door Repair",
        "tech_name": "Tabitha P.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.8,
        "customer_name": "Kyle Lee",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Courtney B.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Krista Ross",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Nicholas H.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.8,
        "customer_name": "Lori Vasquez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Natalie K.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "April Brown",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Joshua J.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-03",
        "review_rate": 5.0,
        "customer_name": "Danielle Waters",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Sheila S.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Eric Byrd",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Katherine D.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-11",
        "review_rate": 5.0,
        "customer_name": "Carla Morales",
        "service_type": "Garage Door Insulation",
        "tech_name": "Brittany S.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-27",
        "review_rate": 5.0,
        "customer_name": "Shannon Barnes",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Sabrina W.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.9,
        "customer_name": "Kristen Charles",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Lori A.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Darryl Anthony",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Ricky B.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.8,
        "customer_name": "Kevin Quinn",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Danny S.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Laura Bolton",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Martin P.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Travis Crawford",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Raymond Y.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.8,
        "customer_name": "Julie Velasquez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Susan S.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Dylan Haynes",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Laura W.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "Billy Bates",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Ashley P.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.8,
        "customer_name": "Dr. Dustin Zhang",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Dustin D.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-26",
        "review_rate": 5.0,
        "customer_name": "Kimberly Powers DVM",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Danielle B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-29",
        "review_rate": 5.0,
        "customer_name": "Diamond Carter",
        "service_type": "Garage Door Installation",
        "tech_name": "Laura D.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Timothy Jordan",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Isabella G.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "William Tucker",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Shawna S.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Joseph Hood",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Eric S.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Matthew Dixon",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Paul G.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Tanya Cooper",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "John B.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Amanda Watts",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jesse D.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.8,
        "customer_name": "Matthew Williams",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Lorraine J.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Adam Vaughn",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Sandra J.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.8,
        "customer_name": "Donald Hernandez",
        "service_type": "Garage Door Opener",
        "tech_name": "Linda P.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.9,
        "customer_name": "Andrew Carter",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Raymond D.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-22",
        "review_rate": 5.0,
        "customer_name": "Sabrina Ford",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Craig K.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-24",
        "review_rate": 5.0,
        "customer_name": "Jason Campbell",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Marcus M.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.8,
        "customer_name": "Deanna Williams",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Scott M.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Leslie Rhodes",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Rebecca W.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Brenda Baldwin",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Rhonda C.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Timothy Young",
        "service_type": "Garage Door Off Track",
        "tech_name": "Steven A.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Mr. Jeffrey Drake",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Arthur C.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Ronald Ramirez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Cindy S.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.8,
        "customer_name": "Brandy Mejia",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Vanessa H.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.8,
        "customer_name": "Andrew Rosales",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Sean M.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.8,
        "customer_name": "Heather Duarte",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jason R.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-18",
        "review_rate": 5.0,
        "customer_name": "Ethan Davidson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Anthony R.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.8,
        "customer_name": "Rebecca Wright",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Keith J.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Danny Choi",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Cynthia R.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.8,
        "customer_name": "Omar Ward",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Cheryl S.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Lindsay Owen",
        "service_type": "Garage Door Insulation",
        "tech_name": "Tonya J.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.8,
        "customer_name": "Traci Edwards DVM",
        "service_type": "Garage Door Insulation",
        "tech_name": "Charles B.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Eddie Hall",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Gary J.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Deborah Taylor",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Natalie C.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-11",
        "review_rate": 5.0,
        "customer_name": "Zachary Mcknight",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Heather B.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-19",
        "review_rate": 5.0,
        "customer_name": "Laura Farmer",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jeffery F.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Logan Oconnor",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Randy B.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-06",
        "review_rate": 5.0,
        "customer_name": "Jacob Mendoza",
        "service_type": "Garage Door Installation",
        "tech_name": "Charles H.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Ashley Moore",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Peter L.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Samantha Wallace",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Connie R.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Charles Moran",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Michael L.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-04",
        "review_rate": 5.0,
        "customer_name": "Mark Nguyen",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Crystal S.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-18",
        "review_rate": 5.0,
        "customer_name": "Diane Ramirez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Melinda G.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Miranda Oneill",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Nancy E.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-14",
        "review_rate": 5.0,
        "customer_name": "Janet Diaz",
        "service_type": "Garage Door Installation",
        "tech_name": "Christina C.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-17",
        "review_rate": 5.0,
        "customer_name": "Tiffany Turner",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Mary R.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Kathryn Savage",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Stephanie H.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Kyle Stewart",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Warren B.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Aaron Kemp",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Taylor R.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.8,
        "customer_name": "Timothy Bell",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Matthew K.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Logan Sharp",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Matthew S.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.8,
        "customer_name": "Stephen Christensen Jr.",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Haley H.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.8,
        "customer_name": "Michael Collins",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Sharon G.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.8,
        "customer_name": "Joshua Decker",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Ross J.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Mary Coffey",
        "service_type": "Garage Door Installation",
        "tech_name": "Kayla T.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Helen Smith",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christy G.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-11",
        "review_rate": 5.0,
        "customer_name": "Michelle Taylor",
        "service_type": "Garage Door Off Track",
        "tech_name": "William M.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.8,
        "customer_name": "Joshua Davis",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Raymond J.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.8,
        "customer_name": "David Washington",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Erin J.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Christopher Wallace",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Mark G.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Sheila Knight",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Linda J.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-27",
        "review_rate": 5.0,
        "customer_name": "Joseph Madden",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Alexander D.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.8,
        "customer_name": "Brooke Parsons",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "David B.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Anderson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Katrina R.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-28",
        "review_rate": 5.0,
        "customer_name": "Lauren Robinson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Bobby C.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.8,
        "customer_name": "Joshua Cowan",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Beth P.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Frederick Sawyer",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "William H.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Isaac Bennett",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Matthew J.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.8,
        "customer_name": "Ashley Williams",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Adam G.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Stanley Ray",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Tiffany H.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Allison Stewart",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jennifer R.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.8,
        "customer_name": "Logan Smith",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Sandra B.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-24",
        "review_rate": 5.0,
        "customer_name": "David Hughes",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Cynthia B.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Anita Thompson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Patricia M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-13",
        "review_rate": 5.0,
        "customer_name": "Mark Morgan",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jared F.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.8,
        "customer_name": "Vincent Terry",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kayla D.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-05",
        "review_rate": 5.0,
        "customer_name": "Ryan Hubbard",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Melissa H.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Daniel Lewis",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Breanna H.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-06",
        "review_rate": 5.0,
        "customer_name": "Tracy Welch",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Andrea H.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-20",
        "review_rate": 5.0,
        "customer_name": "Robert Day",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Austin W.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Bailey",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Patricia P.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-20",
        "review_rate": 5.0,
        "customer_name": "Kevin Becker",
        "service_type": "Residential Garage Door Services",
        "tech_name": "David H.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-30",
        "review_rate": 5.0,
        "customer_name": "William Long",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Christian R.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.8,
        "customer_name": "Joseph Walters",
        "service_type": "Garage Door Repair",
        "tech_name": "Mark H.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.8,
        "customer_name": "Lori Wood",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Megan W.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Timothy Yang",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christina H.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.8,
        "customer_name": "David Montgomery",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michael M.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Adam Hoover",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jose W.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Alyssa Martin",
        "service_type": "Garage Door Insulation",
        "tech_name": "Julie C.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-06",
        "review_rate": 5.0,
        "customer_name": "Dr. Adam Horne",
        "service_type": "Garage Door Installation",
        "tech_name": "Donald M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.8,
        "customer_name": "Brenda Pruitt",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Mary B.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.8,
        "customer_name": "Billy Aguilar",
        "service_type": "Garage Door Services",
        "tech_name": "Crystal S.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.8,
        "customer_name": "Diamond Perez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Nicholas P.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Joshua Keith",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "David S.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Dr. Deborah Hall",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Gregg B.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Ashley Alexander",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Matthew R.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.8,
        "customer_name": "Chelsey Christensen",
        "service_type": "Garage Door Opener",
        "tech_name": "Julie L.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Davis",
        "service_type": "Garage Door Repair",
        "tech_name": "David S.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Lorraine Mclaughlin",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jillian E.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-27",
        "review_rate": 5.0,
        "customer_name": "Terry Lopez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Larry B.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Julie Bates",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Carlos G.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.8,
        "customer_name": "Desiree Gonzalez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Nicole F.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-10",
        "review_rate": 5.0,
        "customer_name": "Dana Perry",
        "service_type": "Garage Door Opener",
        "tech_name": "Brian D.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.8,
        "customer_name": "Jennifer Gonzales",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kathryn B.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Heather Moore",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Yvonne P.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.8,
        "customer_name": "Erik Patton",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Wanda P.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.8,
        "customer_name": "John Hill",
        "service_type": "Garage Door Off Track",
        "tech_name": "Debra M.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Melinda Nelson DDS",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Deanna P.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-15",
        "review_rate": 5.0,
        "customer_name": "Erin Reed",
        "service_type": "Garage Door Opener",
        "tech_name": "Angela S.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-22",
        "review_rate": 5.0,
        "customer_name": "Julie Bradford",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Bryan G.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Carlos Simmons",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Shaun C.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Matthew Tanner",
        "service_type": "Garage Door Track Repair",
        "tech_name": "John E.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Casey Ford",
        "service_type": "Garage Door Opener",
        "tech_name": "Matthew J.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Ashley Allen",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Leonard J.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-24",
        "review_rate": 5.0,
        "customer_name": "Julie Harris",
        "service_type": "Garage Door Repair",
        "tech_name": "John J.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Amber Hill",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kimberly G.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.8,
        "customer_name": "Katherine Anderson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jeffrey L.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.8,
        "customer_name": "Mary Lewis",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Diane W.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Christopher Robinson",
        "service_type": "Garage Door Services",
        "tech_name": "Laura M.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.8,
        "customer_name": "Vanessa Shaw",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christine G.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.8,
        "customer_name": "Nicole Hunter",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Heather M.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.8,
        "customer_name": "Krystal Brown DDS",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jessica B.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.8,
        "customer_name": "Matthew Brown",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Robert L.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-28",
        "review_rate": 5.0,
        "customer_name": "Matthew Peterson",
        "service_type": "Garage Door Services",
        "tech_name": "Eddie B.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-23",
        "review_rate": 5.0,
        "customer_name": "Timothy Maddox",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Mary F.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Christina Stevens",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Rachel R.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Madeline Smith",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Melissa J.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Linda Welch",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Michael H.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Donald Clark",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Brenda S.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-09",
        "review_rate": 5.0,
        "customer_name": "Ariel Smith",
        "service_type": "Garage Door Off Track",
        "tech_name": "Willie S.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Sean Simpson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Brian V.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-02",
        "review_rate": 5.0,
        "customer_name": "Christopher Ho",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Matthew T.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-07",
        "review_rate": 5.0,
        "customer_name": "Ashley Taylor",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Brian B.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Kyle Calderon",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Miranda S.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Preston Moore",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Marie W.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-29",
        "review_rate": 5.0,
        "customer_name": "Dennis Atkins",
        "service_type": "Garage Door Services",
        "tech_name": "Christopher M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Alexander Holden",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Ronald P.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.8,
        "customer_name": "Joshua Hayes",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kristina W.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-21",
        "review_rate": 5.0,
        "customer_name": "Joshua Jordan",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Chad S.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Cindy Delgado",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael T.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-08",
        "review_rate": 5.0,
        "customer_name": "Deanna Decker",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Sheila S.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.8,
        "customer_name": "Michael Crane",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jamie H.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.8,
        "customer_name": "Nicole Hill",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Amanda N.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Chelsea Farmer",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jason T.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Samantha Stone",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Amy J.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Maria Cole",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Samantha L.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Tracy Lewis",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Micheal N.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Carrie Garcia",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jeffrey K.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-27",
        "review_rate": 5.0,
        "customer_name": "Jordan Wood",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Alexandria T.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Andrea White",
        "service_type": "Garage Door Insulation",
        "tech_name": "Katherine M.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Charles Cunningham",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Mary T.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-24",
        "review_rate": 5.0,
        "customer_name": "Danielle Anderson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Dakota H.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.8,
        "customer_name": "Robert Miller",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Denise M.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Bryan Reynolds PhD",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Natalie B.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Charles Hebert",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Cheryl B.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Kristin Thompson",
        "service_type": "Garage Door Services",
        "tech_name": "Jocelyn S.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Sandra Price",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Joseph E.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Kyle Perkins",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jason B.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.8,
        "customer_name": "Lindsay Johnson",
        "service_type": "Garage Door Services",
        "tech_name": "Logan L.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Lee",
        "service_type": "Garage Door Installation",
        "tech_name": "David H.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Tyler Flores",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Leslie H.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Thomas Williams",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Daniel J.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.8,
        "customer_name": "Justin Nixon",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jennifer M.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "William Roth",
        "service_type": "Garage Door Off Track",
        "tech_name": "Melissa R.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-15",
        "review_rate": 5.0,
        "customer_name": "Lindsey Ibarra",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Leslie B.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "Daniel Vasquez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Lauren B.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-24",
        "review_rate": 5.0,
        "customer_name": "Mr. Kerry Pratt",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Krystal B.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Mark Sosa",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Victoria B.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Jessica Chapman",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Erica M.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-10",
        "review_rate": 5.0,
        "customer_name": "Thomas Campos",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "William A.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.8,
        "customer_name": "Raymond Reynolds",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jeremy M.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.8,
        "customer_name": "Steven Silva",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Erin W.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-12",
        "review_rate": 5.0,
        "customer_name": "Larry Dennis",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Andrew F.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Megan Simmons",
        "service_type": "Garage Door Off Track",
        "tech_name": "Melissa R.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-07",
        "review_rate": 5.0,
        "customer_name": "Robert Eaton",
        "service_type": "Garage Door Repair",
        "tech_name": "George B.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.8,
        "customer_name": "Mark Ellison",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Priscilla R.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.8,
        "customer_name": "Sonya Andrews",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Cassie M.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-08",
        "review_rate": 5.0,
        "customer_name": "Alan Johnson",
        "service_type": "Garage Door Repair",
        "tech_name": "Elizabeth G.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Jeanne Finley",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Tara H.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.8,
        "customer_name": "Cassandra Estes",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Veronica D.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.9,
        "customer_name": "Mike Herrera",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Christopher J.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.9,
        "customer_name": "Richard Knight Jr.",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jill F.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.9,
        "customer_name": "Heather Pittman",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Gregory B.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Jonathan Brown",
        "service_type": "Garage Door Installation",
        "tech_name": "Christine R.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Johnathan Dillon",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Matthew A.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Nancy Huffman",
        "service_type": "Garage Door Installation",
        "tech_name": "Daniel W.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.8,
        "customer_name": "Susan Murphy",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Emily W.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-12",
        "review_rate": 5.0,
        "customer_name": "Matthew Fox",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Brenda F.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "Donald Kennedy",
        "service_type": "Garage Door Services",
        "tech_name": "Ashley O.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-13",
        "review_rate": 5.0,
        "customer_name": "Virginia Booker",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Mark H.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Alexandra Murphy",
        "service_type": "Garage Door Off Track",
        "tech_name": "David J.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Cynthia Byrd",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Amanda O.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.8,
        "customer_name": "Ashley Ayala",
        "service_type": "Garage Door Insulation",
        "tech_name": "Eric T.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Molly Simmons",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Julia O.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-01",
        "review_rate": 5.0,
        "customer_name": "Alexandra Johnson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michael C.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-25",
        "review_rate": 5.0,
        "customer_name": "Kimberly Crawford",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Paula W.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Luke Nolan",
        "service_type": "Garage Door Repair",
        "tech_name": "Brian C.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Regina Armstrong",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Carrie R.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-29",
        "review_rate": 5.0,
        "customer_name": "Sean Sanchez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Barbara P.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-16",
        "review_rate": 5.0,
        "customer_name": "Timothy Chandler",
        "service_type": "Garage Door Insulation",
        "tech_name": "Latasha G.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Angela Munoz",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jessica V.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-11",
        "review_rate": 5.0,
        "customer_name": "Corey Nguyen",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Tammy F.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Michael Brown",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kimberly W.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.8,
        "customer_name": "Mitchell Hays",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Megan M.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.8,
        "customer_name": "Anna Foley",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Karen G.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Emily Evans",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Tina W.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-03",
        "review_rate": 5.0,
        "customer_name": "Emily Rivera",
        "service_type": "Garage Door Services",
        "tech_name": "Meghan W.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Sara Marsh",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Clayton W.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Valentine",
        "service_type": "Garage Door Services",
        "tech_name": "Steven H.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Aaron Moreno",
        "service_type": "Garage Door Insulation",
        "tech_name": "Joseph N.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Ashley Rodriguez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Anthony J.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "John Thomas",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Sandy B.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Tiffany Campbell",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "William V.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-19",
        "review_rate": 5.0,
        "customer_name": "Billy Wilson",
        "service_type": "Garage Door Services",
        "tech_name": "Alicia J.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Julie Tapia",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Stephen R.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Jessica Frazier DDS",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kristina G.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Laura Walsh",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "William P.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "James Frazier",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Miranda C.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Cindy Jenkins",
        "service_type": "Garage Door Off Track",
        "tech_name": "Timothy G.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.8,
        "customer_name": "Megan Clark",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Gregory M.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-15",
        "review_rate": 5.0,
        "customer_name": "Lisa Ferrell",
        "service_type": "Garage Door Installation",
        "tech_name": "Natasha L.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Amy Diaz",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Adam M.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "Heather Riley",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Gregory B.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-30",
        "review_rate": 5.0,
        "customer_name": "Thomas Mcguire",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Ryan F.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.8,
        "customer_name": "Jennifer Lopez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Stephanie A.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-12",
        "review_rate": 5.0,
        "customer_name": "Larry Griffin",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Timothy S.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.8,
        "customer_name": "Luis Gamble",
        "service_type": "Garage Door Off Track",
        "tech_name": "Renee E.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.9,
        "customer_name": "William Robinson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Michael J.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Margaret Chang",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kimberly A.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Ryan Gray",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Brianna H.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Cheryl Watson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Courtney T.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Martha Stanley MD",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Morgan W.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-18",
        "review_rate": 5.0,
        "customer_name": "David Phillips",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Ashley R.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Ronald Zamora",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Anthony A.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-10",
        "review_rate": 5.0,
        "customer_name": "Chelsea Williams",
        "service_type": "Garage Door Services",
        "tech_name": "Kimberly B.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-19",
        "review_rate": 5.0,
        "customer_name": "Thomas Turner",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Rebecca C.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Christina Ochoa",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Gina S.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-29",
        "review_rate": 5.0,
        "customer_name": "Troy Brooks",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Tiffany N.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-25",
        "review_rate": 5.0,
        "customer_name": "Linda Castro",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Elizabeth C.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Jasmine Jones",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Laurie H.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-17",
        "review_rate": 5.0,
        "customer_name": "James Shaw",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Joshua S.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-29",
        "review_rate": 5.0,
        "customer_name": "Patrick Campbell",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Linda Y.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.8,
        "customer_name": "Katherine Reed",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Deborah T.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Joseph Clark",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Caitlin G.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Madison Chapman",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Erin S.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "Deborah Simpson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Alexandra L.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.8,
        "customer_name": "Karen Reyes",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Bradley T.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Jennifer Arnold",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Mark J.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Brian Fletcher",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Katherine L.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Nicholas Jones",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Peter C.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.8,
        "customer_name": "Caitlin Moore",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Willie W.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.8,
        "customer_name": "Alexander Wilson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Elizabeth M.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-28",
        "review_rate": 5.0,
        "customer_name": "Andrew Herrera",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Robert B.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-18",
        "review_rate": 5.0,
        "customer_name": "Courtney Barrett",
        "service_type": "Garage Door Installation",
        "tech_name": "Rick H.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Janet Benson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Pamela H.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.8,
        "customer_name": "Matthew Kline",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jennifer W.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-18",
        "review_rate": 5.0,
        "customer_name": "Lauren Cohen",
        "service_type": "Garage Door Services",
        "tech_name": "Emily E.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "David Riley",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Erin S.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Margaret Odonnell",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Mary P.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Arthur Crawford",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kathryn B.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Justin Jacobs",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Ronald B.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "Paul Calderon",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jacob B.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Melissa Wood",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Malik C.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.8,
        "customer_name": "George Small",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michael M.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Kaitlin Douglas",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Leah V.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.8,
        "customer_name": "Melanie Robinson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jeffery R.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.9,
        "customer_name": "Sheri Lee",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Cory H.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Kelsey Jones",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Michael F.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.8,
        "customer_name": "Lauren Wells",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Melinda L.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.8,
        "customer_name": "Christina Mills",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Steven M.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Dr. David Murphy",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Margaret R.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.8,
        "customer_name": "Kenneth Rowe",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jeffery D.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.8,
        "customer_name": "Brent Davis",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Tyler C.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-18",
        "review_rate": 5.0,
        "customer_name": "Deborah Bailey",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Brian D.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Natalie Carter",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Sharon D.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-22",
        "review_rate": 5.0,
        "customer_name": "Gerald Johnson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Samantha F.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Lisa Casey",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Erik H.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Sonya Nelson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Joseph R.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Robert Castaneda",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Robert J.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Adrian Keith",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Ethan H.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-22",
        "review_rate": 5.0,
        "customer_name": "Paul Taylor",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Lawrence Y.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.8,
        "customer_name": "Brian Sanders",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Lisa A.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Megan Howell",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Gary C.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-26",
        "review_rate": 5.0,
        "customer_name": "Ashley Cortez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Allison R.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-24",
        "review_rate": 5.0,
        "customer_name": "Amanda Wilson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Anna C.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Stephanie Abbott",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "John O.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Joshua Sharp",
        "service_type": "Garage Door Off Track",
        "tech_name": "Andrea B.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-22",
        "review_rate": 5.0,
        "customer_name": "Amanda Parker",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Brian H.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Molly Landry",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Nicholas A.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-20",
        "review_rate": 5.0,
        "customer_name": "Robert Higgins",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jared H.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Barbara Griffin",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Gwendolyn B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Nathan Anderson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "John D.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-14",
        "review_rate": 5.0,
        "customer_name": "Frank Freeman",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Douglas R.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.8,
        "customer_name": "Alan Rowland",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Ronald C.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-28",
        "review_rate": 5.0,
        "customer_name": "Frank Wolfe",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kathryn R.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.8,
        "customer_name": "Dale Dickerson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Alan C.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Joshua Gonzalez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Laura S.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.8,
        "customer_name": "Angelica Howell",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Julie W.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.8,
        "customer_name": "Troy Martinez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Calvin J.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Rhonda Trujillo",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Walter L.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.8,
        "customer_name": "Richard Parker",
        "service_type": "Garage Door Opener",
        "tech_name": "Melissa M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Rachel Stone",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Brian Y.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.8,
        "customer_name": "Dana Pennington",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Joseph H.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Robin Yu",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Emily M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "David Perry",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Chad P.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Thomas Benson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Tyler W.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Anthony Gray",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jacob N.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.8,
        "customer_name": "Tamara Jefferson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Darryl K.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.8,
        "customer_name": "Stephanie Huff",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Amy C.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.8,
        "customer_name": "Latoya Schmidt",
        "service_type": "Garage Door Opener",
        "tech_name": "Timothy S.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.9,
        "customer_name": "Jeremy Hayes",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Michael J.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.8,
        "customer_name": "Stacey Crawford",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Manuel S.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.8,
        "customer_name": "Sean Cunningham",
        "service_type": "Garage Door Repair",
        "tech_name": "Mario C.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Debra Mitchell",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Ashley B.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Cole Reese",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Johnathan R.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Christopher Moore",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Patrick R.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.8,
        "customer_name": "Adam Martinez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Nicholas G.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Cesar Aguirre",
        "service_type": "Garage Door Track Repair",
        "tech_name": "William H.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Terry Moreno",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Paul C.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Rachel Levy",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Timothy S.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.8,
        "customer_name": "Brendan Garcia",
        "service_type": "Garage Door Opener",
        "tech_name": "John C.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Kristina Oliver",
        "service_type": "Garage Door Opener",
        "tech_name": "Andrew P.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Jessica Whitehead",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Stephen W.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Taylor Allen",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Tony F.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Michael King",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jeremy D.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-09-26",
        "review_rate": 5.0,
        "customer_name": "Jeremy Williams",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Carrie H.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-03",
        "review_rate": 5.0,
        "customer_name": "Kurt Quinn",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "James J.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Kimberly Proctor",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Zachary M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Ryan Wheeler",
        "service_type": "Garage Door Off Track",
        "tech_name": "James L.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-29",
        "review_rate": 5.0,
        "customer_name": "Daniel Dalton",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Patricia H.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Lee Sherman MD",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Gary W.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-31",
        "review_rate": 5.0,
        "customer_name": "Margaret Thomas",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jermaine A.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.8,
        "customer_name": "Jordan Mills",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Melissa H.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-20",
        "review_rate": 5.0,
        "customer_name": "Cody Blackwell",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Eric B.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.8,
        "customer_name": "Christine Powell",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Bradley D.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.8,
        "customer_name": "William Nichols",
        "service_type": "Garage Door Services",
        "tech_name": "Caitlin H.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.8,
        "customer_name": "Michael Pena",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jeremy J.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Vickie Jordan",
        "service_type": "Garage Door Opener",
        "tech_name": "Steven D.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Hannah Smith",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Fernando H.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Gary Powell",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jeffrey Y.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Alexis Williams",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kimberly H.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-30",
        "review_rate": 5.0,
        "customer_name": "Sarah Miller",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Katherine R.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Justin Adams Jr.",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Richard S.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "William Booker",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jodi S.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-08",
        "review_rate": 5.0,
        "customer_name": "Jamie Yates",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "John P.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Kelly Brooks",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Amanda P.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Krystal Turner",
        "service_type": "Garage Door Services",
        "tech_name": "Randy M.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.8,
        "customer_name": "Renee Anderson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Brian S.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Angel Wilson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jasmine F.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.8,
        "customer_name": "Michael Hahn",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Melinda M.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-27",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Gilbert",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Colin C.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "James Evans",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Candace W.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "James Kelly",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Rebecca C.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.9,
        "customer_name": "Robert Krueger",
        "service_type": "Garage Door Repair",
        "tech_name": "William R.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Michael Wilson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Heather T.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Brandon Brooks",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Allison J.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-15",
        "review_rate": 5.0,
        "customer_name": "Christine Wise",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Joan W.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Anthony Murray",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Tina A.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-10",
        "review_rate": 5.0,
        "customer_name": "Andrew Perry",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Shannon L.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.9,
        "customer_name": "Connor Graham",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Elijah D.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-04",
        "review_rate": 5.0,
        "customer_name": "Christopher Nunez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Caitlin C.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "James Acosta",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Ryan S.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Faith Vazquez",
        "service_type": "Garage Door Services",
        "tech_name": "Christopher B.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-07",
        "review_rate": 5.0,
        "customer_name": "Michael Smith",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Richard P.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Shane Gonzalez",
        "service_type": "Garage Door Opener",
        "tech_name": "Katherine O.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-26",
        "review_rate": 5.0,
        "customer_name": "Lisa Carter",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "David K.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.8,
        "customer_name": "Henry Martinez",
        "service_type": "Garage Door Opener",
        "tech_name": "Matthew R.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Jack Wilson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jacqueline M.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-07",
        "review_rate": 5.0,
        "customer_name": "Angelica Kelly",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jamie P.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.8,
        "customer_name": "Jessica Rodriguez",
        "service_type": "Garage Door Opener",
        "tech_name": "Megan L.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Brittany Smith",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jeffrey S.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Brandy Huff",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michael P.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.8,
        "customer_name": "William Carter",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Samuel K.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Brent Hensley",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Brittany H.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.8,
        "customer_name": "Randy Garcia",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Lisa W.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.9,
        "customer_name": "Tracie Ball",
        "service_type": "Garage Door Insulation",
        "tech_name": "Ashley G.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Christine Bell",
        "service_type": "Garage Door Repair",
        "tech_name": "Laurie G.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.8,
        "customer_name": "Amy Church",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Cindy B.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Benjamin Vasquez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jay L.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.8,
        "customer_name": "Michele Reed",
        "service_type": "Garage Door Services",
        "tech_name": "Brandon L.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.8,
        "customer_name": "Tracy Barrera",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Laura S.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Jordan Anderson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Susan P.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Michele Mitchell",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Joseph H.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Jesus Brandt",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Daniel R.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-11",
        "review_rate": 5.0,
        "customer_name": "Tamara Hill",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Samuel D.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Timothy Frank",
        "service_type": "Garage Door Insulation",
        "tech_name": "Matthew L.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-02",
        "review_rate": 5.0,
        "customer_name": "Austin Robinson",
        "service_type": "Garage Door Repair",
        "tech_name": "Amy M.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-11",
        "review_rate": 5.0,
        "customer_name": "Nicole Conner",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Clarence M.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.9,
        "customer_name": "Danielle Walls DDS",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kathy U.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.9,
        "customer_name": "Kathy Hall",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Melanie H.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Eric Ross",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jessica S.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-21",
        "review_rate": 5.0,
        "customer_name": "Christopher Simmons",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Matthew F.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Eugene Oneal",
        "service_type": "Garage Door Services",
        "tech_name": "Matthew C.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.8,
        "customer_name": "Joseph Wade",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Megan M.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Bonnie Wilkins",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Paul B.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Michael Cunningham",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Randall J.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.9,
        "customer_name": "Evan Vaughn",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Parker P.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-12",
        "review_rate": 5.0,
        "customer_name": "Michael Harmon",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Maria J.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Debbie Maynard",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Virginia T.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Amber Ross",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Todd S.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-05",
        "review_rate": 5.0,
        "customer_name": "Amy Vang",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Darryl G.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Taylor Dickerson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Joseph B.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-21",
        "review_rate": 5.0,
        "customer_name": "Caitlyn Allen",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Daniel A.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.9,
        "customer_name": "Philip Keller",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Lisa B.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Alexa Cherry",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Nicholas C.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.8,
        "customer_name": "Michaela Gomez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Sarah J.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.8,
        "customer_name": "John Hurst",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Todd K.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.8,
        "customer_name": "Stephanie Lowery",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Chelsea C.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Fred Glenn",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Anthony S.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.8,
        "customer_name": "Dean Hanson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Elizabeth R.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Karen Graham",
        "service_type": "Garage Door Track Repair",
        "tech_name": "William M.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.9,
        "customer_name": "Nathaniel Boyd",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Andrew S.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Jeremiah Wells",
        "service_type": "Garage Door Installation",
        "tech_name": "Michael O.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Matthew Keller",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Stephanie F.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "John Johnson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Edgar D.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.8,
        "customer_name": "Christine Rodriguez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Robert C.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.8,
        "customer_name": "Ryan Taylor",
        "service_type": "Garage Door Repair",
        "tech_name": "Kristina M.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Bradley Atkins",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Shannon S.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Rebecca Smith",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kayla L.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Andrew Fisher",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "William W.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.8,
        "customer_name": "Yvonne Norton",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Frank B.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-13",
        "review_rate": 5.0,
        "customer_name": "Linda Mcbride",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Brandy A.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Stacie Palmer",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Ryan L.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.8,
        "customer_name": "Mary Barber",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Susan M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-17",
        "review_rate": 5.0,
        "customer_name": "Brittany Farley",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Crystal B.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Cory Arnold",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Marie W.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Megan Grant",
        "service_type": "Garage Door Services",
        "tech_name": "Brian H.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Joshua Weaver",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michelle S.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-27",
        "review_rate": 5.0,
        "customer_name": "Trevor White",
        "service_type": "Garage Door Services",
        "tech_name": "Rebecca S.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Tanya Sanchez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Ronald W.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Laurie Watson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Samantha D.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-26",
        "review_rate": 5.0,
        "customer_name": "Ashley Dougherty",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Nicholas H.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Edward White",
        "service_type": "Garage Door Installation",
        "tech_name": "Elizabeth P.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Karen Holloway PhD",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "William L.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.8,
        "customer_name": "Brian Ross",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jennifer G.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-29",
        "review_rate": 5.0,
        "customer_name": "Lisa Thompson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Mark B.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Daniel Garcia",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Tracey B.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Jose Sims",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Angela B.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Lindsay Cooper",
        "service_type": "Garage Door Repair",
        "tech_name": "Mitchell B.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "John Ortiz",
        "service_type": "Garage Door Insulation",
        "tech_name": "Rodney F.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.8,
        "customer_name": "Jonathan Becker",
        "service_type": "Garage Door Repair",
        "tech_name": "Jessica W.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.8,
        "customer_name": "Richard Walsh",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Stephen H.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Yvonne Reeves",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Andre D.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Christopher Jenkins",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Henry B.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Joseph Larsen",
        "service_type": "Garage Door Opener",
        "tech_name": "Rachel J.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.8,
        "customer_name": "Randy Garcia",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Robert L.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "William Hudson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kayla L.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "April Scott",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Sarah C.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Erica Martinez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Andrea M.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.9,
        "customer_name": "Julie Carter",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "John H.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-24",
        "review_rate": 5.0,
        "customer_name": "Kevin Garcia",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Nicole W.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.8,
        "customer_name": "Phyllis Woodward",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Courtney M.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.8,
        "customer_name": "Christopher Sparks",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kelly T.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Samuel Carson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Steven A.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.8,
        "customer_name": "Sara Harris",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Heather L.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-16",
        "review_rate": 5.0,
        "customer_name": "Jesse White",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Gregory C.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.9,
        "customer_name": "Travis Todd",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Adriana R.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-16",
        "review_rate": 5.0,
        "customer_name": "Miranda Price",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Brenda M.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "Linda Trevino",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Gerald H.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-11",
        "review_rate": 5.0,
        "customer_name": "Emily Nunez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Susan G.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Alexis Hunter",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Sean L.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Kevin Lutz",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kyle L.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Rebecca Silva",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Daniel R.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Luke Morgan",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Rachel B.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Alejandra Wells",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Ronald S.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.8,
        "customer_name": "Mr. John Smith III",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Matthew C.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Melissa Lawson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jared B.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.8,
        "customer_name": "Amber Higgins",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Russell J.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Matthew Bentley",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Steven M.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-19",
        "review_rate": 5.0,
        "customer_name": "Billy Cunningham",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Melissa B.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Perkins",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Brett S.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.8,
        "customer_name": "Travis Alexander",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Walter V.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-12",
        "review_rate": 5.0,
        "customer_name": "Austin James",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Denise F.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Robin Robles MD",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Karen W.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.8,
        "customer_name": "Bruce Wilson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Glenn H.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Lori Johnson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Veronica P.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-06",
        "review_rate": 5.0,
        "customer_name": "Joel Jackson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Robin A.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Stephen Kim",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Heather O.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Joshua King",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Gina V.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-24",
        "review_rate": 5.0,
        "customer_name": "Michael Joseph",
        "service_type": "Garage Door Off Track",
        "tech_name": "Nicholas S.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Amanda Wilson",
        "service_type": "Garage Door Installation",
        "tech_name": "William M.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.9,
        "customer_name": "Melissa Jackson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "William S.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.8,
        "customer_name": "Mr. Michael Stevens",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kayla F.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Lydia Lane",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jacob W.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.8,
        "customer_name": "Cody Townsend",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Ann B.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-22",
        "review_rate": 5.0,
        "customer_name": "Shannon Patterson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Patricia M.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "John Brown",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Morgan H.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Breanna White",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Thomas M.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-07",
        "review_rate": 5.0,
        "customer_name": "Emily Hobbs",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Dominique M.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Sheena Clark",
        "service_type": "Garage Door Services",
        "tech_name": "Carol T.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Luke Velez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Laura M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.8,
        "customer_name": "Michael Barker",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Maria M.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Eric Galloway",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Nicholas D.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-18",
        "review_rate": 5.0,
        "customer_name": "Marissa Ramirez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Robert N.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-20",
        "review_rate": 5.0,
        "customer_name": "Lance Moss",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Vincent P.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-16",
        "review_rate": 5.0,
        "customer_name": "Michael Ortiz",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Paula P.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Kelsey Stein",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Richard L.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Julie Bradley",
        "service_type": "Garage Door Off Track",
        "tech_name": "Tiffany J.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Colleen Harvey",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Erin S.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.8,
        "customer_name": "Robert Walker II",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Lisa B.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.8,
        "customer_name": "Brandon Henry",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Sarah S.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.9,
        "customer_name": "Louis Smith",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Richard J.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Rhonda Fox",
        "service_type": "Garage Door Repair",
        "tech_name": "Elizabeth S.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Nicole Rodriguez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Allen R.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-14",
        "review_rate": 5.0,
        "customer_name": "James Little",
        "service_type": "Garage Door Installation",
        "tech_name": "Carla R.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-31",
        "review_rate": 5.0,
        "customer_name": "Mark Williamson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "David B.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Angela Robertson",
        "service_type": "Garage Door Repair",
        "tech_name": "Shelley W.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-09-29",
        "review_rate": 5.0,
        "customer_name": "Kimberly Wilson",
        "service_type": "Garage Door Opener",
        "tech_name": "Joseph B.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Brendan Shelton",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Dustin A.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-20",
        "review_rate": 5.0,
        "customer_name": "Jason Adams",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Alexa D.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-01",
        "review_rate": 5.0,
        "customer_name": "Matthew Arnold",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "David R.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-02",
        "review_rate": 5.0,
        "customer_name": "William Olsen",
        "service_type": "Garage Door Repair",
        "tech_name": "Jamie H.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.8,
        "customer_name": "Catherine Spencer",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jennifer J.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.8,
        "customer_name": "Jaime Flores",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jonathan K.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Robert Carlson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kelly R.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.8,
        "customer_name": "Susan Cooper",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Bryan M.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-27",
        "review_rate": 5.0,
        "customer_name": "Michael Perez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kevin N.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-28",
        "review_rate": 5.0,
        "customer_name": "Robert Lucero",
        "service_type": "Garage Door Installation",
        "tech_name": "Cody D.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.8,
        "customer_name": "Maria Hancock",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Carlos C.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.8,
        "customer_name": "Reginald Dickerson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Russell J.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Jesus Ware",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Donald C.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Charles Smith",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Russell V.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-18",
        "review_rate": 5.0,
        "customer_name": "Lori Kim",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Hannah S.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Jane Nichols",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Lori R.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Edward Shepherd",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Scott B.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Robert Hoffman",
        "service_type": "Garage Door Off Track",
        "tech_name": "Ashley M.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Phillip Brown",
        "service_type": "Residential Garage Door Services",
        "tech_name": "James J.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Stuart Griffin",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Erica H.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Shawn Hall",
        "service_type": "Garage Door Services",
        "tech_name": "Brian M.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Jesus Jackson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Cynthia C.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Jason Hicks",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Erin R.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.8,
        "customer_name": "Kathryn Burch",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Cheryl W.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-19",
        "review_rate": 5.0,
        "customer_name": "Jessica Smith",
        "service_type": "Garage Door Installation",
        "tech_name": "David F.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-11",
        "review_rate": 5.0,
        "customer_name": "Courtney Vasquez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Michael H.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Mrs. Amber Wheeler DDS",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Regina W.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-13",
        "review_rate": 5.0,
        "customer_name": "Peter Martin",
        "service_type": "Garage Door Off Track",
        "tech_name": "Valerie S.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.8,
        "customer_name": "Ryan Peterson",
        "service_type": "Garage Door Repair",
        "tech_name": "James M.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Jacob Harris",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Danny B.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Shelly Nguyen",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Brandi B.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Robin Anderson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Laura F.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.8,
        "customer_name": "Michael Edwards",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Ashley R.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Christine Foster MD",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Katherine R.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Jason Gonzalez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Wesley B.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-25",
        "review_rate": 5.0,
        "customer_name": "Robert Pearson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jennifer H.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Richard Chambers",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kevin F.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.8,
        "customer_name": "Jacob Love",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Debra W.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "John Richardson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Karen H.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Joan Nguyen",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kimberly H.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Johnathan Trujillo",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Raymond N.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "James Smith",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Gabriel P.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-22",
        "review_rate": 5.0,
        "customer_name": "Rebekah Sanders",
        "service_type": "Garage Door Installation",
        "tech_name": "James B.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.8,
        "customer_name": "Christine Parker",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Richard M.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.8,
        "customer_name": "David Cruz",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Troy A.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.8,
        "customer_name": "Shannon Yates",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Shannon R.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.8,
        "customer_name": "Nicole Brown",
        "service_type": "Garage Door Opener",
        "tech_name": "Paige L.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Matthew Sanchez",
        "service_type": "Garage Door Repair",
        "tech_name": "Pamela P.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-01",
        "review_rate": 5.0,
        "customer_name": "John Cunningham",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Robert B.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Ronald Jones",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Debbie W.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-26",
        "review_rate": 5.0,
        "customer_name": "Thomas Carter",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Joanna R.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-22",
        "review_rate": 5.0,
        "customer_name": "Charles Berry",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Abigail W.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "Richard Williams",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Patricia C.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Matthew Garrett",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jennifer R.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.8,
        "customer_name": "David English",
        "service_type": "Garage Door Repair",
        "tech_name": "Bryan A.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-15",
        "review_rate": 5.0,
        "customer_name": "James King",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Anita G.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Dustin Montoya",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Alexa H.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.8,
        "customer_name": "Robert Nash",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Christina T.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.8,
        "customer_name": "Christian Gordon",
        "service_type": "Garage Door Services",
        "tech_name": "Sandra G.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.8,
        "customer_name": "Traci Weaver",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Karen H.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-12",
        "review_rate": 5.0,
        "customer_name": "Robert Johnson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Martha H.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-29",
        "review_rate": 5.0,
        "customer_name": "Deborah Pace",
        "service_type": "Garage Door Insulation",
        "tech_name": "Isaiah S.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-08",
        "review_rate": 5.0,
        "customer_name": "David Forbes",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Meghan R.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.8,
        "customer_name": "Nancy Lang",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Micheal B.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Tammy Wilson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kimberly B.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-28",
        "review_rate": 5.0,
        "customer_name": "Melinda Burnett",
        "service_type": "Garage Door Services",
        "tech_name": "Michele F.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.8,
        "customer_name": "Annette Craig",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Steven S.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.8,
        "customer_name": "Sarah Cordova",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jon C.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-09",
        "review_rate": 5.0,
        "customer_name": "Brendan Johnson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Tina R.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.8,
        "customer_name": "Susan Johnson",
        "service_type": "Garage Door Insulation",
        "tech_name": "James W.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.8,
        "customer_name": "Nathan Velasquez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Craig M.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.8,
        "customer_name": "Christopher Leonard",
        "service_type": "Garage Door Opener",
        "tech_name": "Shannon C.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-18",
        "review_rate": 5.0,
        "customer_name": "Rachel Rodriguez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jasmine K.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-07",
        "review_rate": 5.0,
        "customer_name": "Paul Rogers",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Stephen W.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Melissa Shaw",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Tammy T.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-18",
        "review_rate": 5.0,
        "customer_name": "Eric Salinas",
        "service_type": "Garage Door Off Track",
        "tech_name": "Donald E.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-06",
        "review_rate": 5.0,
        "customer_name": "Maria Preston",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jeffrey S.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-03",
        "review_rate": 5.0,
        "customer_name": "Lori Russo",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Craig W.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-15",
        "review_rate": 5.0,
        "customer_name": "Thomas Green",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Matthew G.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-22",
        "review_rate": 5.0,
        "customer_name": "Lance Rosario",
        "service_type": "Garage Door Off Track",
        "tech_name": "Angela E.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Nicole Dominguez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Douglas L.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Kimberly Mcdonald",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Tara L.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Amy Jackson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Joseph C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-14",
        "review_rate": 5.0,
        "customer_name": "Julie Thornton",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Heather A.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Robert Foster",
        "service_type": "Garage Door Repair",
        "tech_name": "Derrick H.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-31",
        "review_rate": 5.0,
        "customer_name": "Kevin Patel",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Molly J.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-04",
        "review_rate": 5.0,
        "customer_name": "Victoria Myers",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Zachary R.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-26",
        "review_rate": 5.0,
        "customer_name": "Patricia Thomas",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Barbara R.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.8,
        "customer_name": "Timothy Butler",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Johnny P.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-19",
        "review_rate": 5.0,
        "customer_name": "Mr. Robert Ross",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "James H.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Adam Michael",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Nancy S.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Maria Griffin",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kristy B.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Travis Rodriguez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Michael C.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Michael Cook",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Ashley G.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.9,
        "customer_name": "Andrew White",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Lisa S.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Harry Allison",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Valerie P.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.8,
        "customer_name": "Danielle Klein",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jose C.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-15",
        "review_rate": 5.0,
        "customer_name": "Rebecca Lang",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Erika D.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-03",
        "review_rate": 5.0,
        "customer_name": "Stephanie Pace",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Michael D.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Mary Tapia",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Laura L.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.8,
        "customer_name": "Terry Stone",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Lori R.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.8,
        "customer_name": "Shelley Collins",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jonathan C.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-26",
        "review_rate": 5.0,
        "customer_name": "Richard Sullivan",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Cindy R.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Rita Olson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Taylor C.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Christopher Johnston",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Wyatt M.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Donald Garrett",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Derek A.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.9,
        "customer_name": "Nancy Tucker",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jenny B.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-11",
        "review_rate": 5.0,
        "customer_name": "Juan Jones",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Michelle E.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.8,
        "customer_name": "Adam Rivas",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Stephanie D.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.8,
        "customer_name": "Eduardo Richards",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Daniel G.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Jason Robinson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Phillip J.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-01",
        "review_rate": 5.0,
        "customer_name": "Christopher Morgan",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jill M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-03",
        "review_rate": 5.0,
        "customer_name": "Derek Taylor",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Andrew S.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.8,
        "customer_name": "Shelby Lawson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Daniel P.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.8,
        "customer_name": "Kim Underwood",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Sharon V.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Jesse Fleming",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Larry D.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.8,
        "customer_name": "Joseph Ibarra",
        "service_type": "Garage Door Installation",
        "tech_name": "Christopher M.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-04",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Mckinney",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kenneth W.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-05",
        "review_rate": 5.0,
        "customer_name": "John Medina",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "James K.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-12",
        "review_rate": 5.0,
        "customer_name": "James Allen",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Susan B.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "William Daniels",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Victor S.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Penny Herrera",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Taylor H.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "Jason Campbell",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Audrey A.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.8,
        "customer_name": "Bradley Alvarez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Charles C.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Heidi Chase",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Michelle B.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Sierra Murray",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jeremy W.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Candice Cain",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Reginald S.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Madison Hughes",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Michael B.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Stacey Zavala",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Laura Z.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.8,
        "customer_name": "Jose Gonzalez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Andrea F.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-16",
        "review_rate": 5.0,
        "customer_name": "Ryan Mooney",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jeremy K.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.9,
        "customer_name": "Jennifer Thompson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Erica W.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "Martin Mayer",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jack C.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Michelle Roberts",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "William R.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-14",
        "review_rate": 5.0,
        "customer_name": "Andrew Marshall",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Ryan B.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.8,
        "customer_name": "Lisa Brown",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Denise E.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.8,
        "customer_name": "Garrett Campbell",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jacqueline W.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Virginia Parker",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Bruce W.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Allen Bowen",
        "service_type": "Garage Door Opener",
        "tech_name": "Amanda M.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Laura Molina",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Sandra G.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-26",
        "review_rate": 5.0,
        "customer_name": "Tracy Richardson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Alexis L.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-17",
        "review_rate": 5.0,
        "customer_name": "Margaret Kelly",
        "service_type": "Garage Door Track Repair",
        "tech_name": "William H.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.8,
        "customer_name": "Katrina Miller",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Richard N.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.8,
        "customer_name": "Kathleen Patterson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "William R.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Gregory Frazier",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Michael F.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Nicholas Sharp",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jennifer A.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Amy Cooper",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Bonnie C.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-22",
        "review_rate": 5.0,
        "customer_name": "Walter Stone",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jennifer B.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Flores",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Katie Y.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Lisa Clarke",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brendan G.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-27",
        "review_rate": 5.0,
        "customer_name": "Monica Ryan",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Becky B.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.8,
        "customer_name": "Brandon Holt",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Steven K.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Lance Galloway",
        "service_type": "Garage Door Opener",
        "tech_name": "Tanner J.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.8,
        "customer_name": "Christian Hopkins",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Christopher K.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.8,
        "customer_name": "Misty Johnson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Yvonne A.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.8,
        "customer_name": "Eugene Frost",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Sean B.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-08",
        "review_rate": 5.0,
        "customer_name": "Marc Jones MD",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Vanessa M.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Laura Moreno",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kenneth C.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.8,
        "customer_name": "Deborah Ali",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Michael H.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.8,
        "customer_name": "Scott Brown",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Holly E.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.8,
        "customer_name": "Cynthia Price",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Rebecca C.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Stephanie Anderson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Sara A.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Katrina Patterson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Taylor J.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.8,
        "customer_name": "Lisa Salinas",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jose J.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-17",
        "review_rate": 5.0,
        "customer_name": "Gerald Walters",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Phillip H.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-16",
        "review_rate": 5.0,
        "customer_name": "Kylie Green",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Angela S.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.8,
        "customer_name": "Joshua Fuentes",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Danny H.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Leonard Thompson",
        "service_type": "Garage Door Installation",
        "tech_name": "John M.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Mark Armstrong",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "John H.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-13",
        "review_rate": 5.0,
        "customer_name": "Steven Leonard",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Richard D.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Christopher Stevens",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Nathaniel C.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-19",
        "review_rate": 5.0,
        "customer_name": "Devin Johnson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Carol C.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-20",
        "review_rate": 5.0,
        "customer_name": "Jessica Vargas",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Sara J.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.8,
        "customer_name": "Jessica Hudson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jocelyn G.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.8,
        "customer_name": "Debbie Callahan",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kelly J.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Donald Thomas",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Phillip O.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.8,
        "customer_name": "David Brown",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Joshua C.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Michelle Fleming",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Brittany S.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Michelle Wilson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Catherine P.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "Stephanie Fuller",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kathryn J.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Derek King",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Cindy P.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Lynn Smith",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Martin W.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Paul Hughes",
        "service_type": "Garage Door Opener",
        "tech_name": "Keith S.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-16",
        "review_rate": 5.0,
        "customer_name": "Madison Gillespie",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "William T.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.8,
        "customer_name": "Cassandra Grant",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kristin I.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-11",
        "review_rate": 5.0,
        "customer_name": "Heather Henderson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Ronald L.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Alexander Garcia",
        "service_type": "Garage Door Off Track",
        "tech_name": "Robert L.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-04",
        "review_rate": 5.0,
        "customer_name": "Jaime Sparks",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Angela W.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.8,
        "customer_name": "Kenneth Lindsey",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Crystal M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.8,
        "customer_name": "Michele Aguilar",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Lindsay R.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Frank Lambert",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Megan M.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Teresa Silva",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Eric W.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Donna Cox",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Crystal A.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Heather Morgan",
        "service_type": "Garage Door Insulation",
        "tech_name": "Tyler B.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-12",
        "review_rate": 5.0,
        "customer_name": "Tracy Cunningham",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Aaron L.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "Shaun Alvarado",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Victoria L.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Hannah Johnson",
        "service_type": "Garage Door Repair",
        "tech_name": "Rachel S.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Amanda Taylor",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Andrea D.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "Justin Harrington",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Stephanie B.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Roger Jenkins",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Sherry R.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.8,
        "customer_name": "Jeanne Leblanc",
        "service_type": "Garage Door Maintenance",
        "tech_name": "William S.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.8,
        "customer_name": "Shane Griffin",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Erin R.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-17",
        "review_rate": 5.0,
        "customer_name": "Alyssa Pearson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Julie S.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-16",
        "review_rate": 5.0,
        "customer_name": "Vanessa Hatfield",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Elizabeth N.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.8,
        "customer_name": "Jennifer Mcmillan",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jennifer G.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Lindsay Strickland",
        "service_type": "Garage Door Insulation",
        "tech_name": "Brooke J.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Nathan Crosby",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Samantha L.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Erica Brown",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Crystal H.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.8,
        "customer_name": "Luke Dalton",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Phillip M.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Emily Kemp",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Danielle B.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.8,
        "customer_name": "Daniel Hill",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Linda P.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Jose Webster",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jason N.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Leroy Fritz",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Mary K.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-21",
        "review_rate": 5.0,
        "customer_name": "Jamie Cooper",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Arthur H.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Travis Neal",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Carol S.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Jamie Gonzales",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Peter M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Randall Sanders",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Holly C.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Katie Harrington",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jonathan W.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Joseph Rivera",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Lauren R.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "Tina Dyer",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Charles M.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Nicholas Melendez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Xavier M.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.8,
        "customer_name": "Joanne Berry",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Priscilla M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Veronica Ramsey",
        "service_type": "Garage Door Insulation",
        "tech_name": "Nicholas F.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-12",
        "review_rate": 5.0,
        "customer_name": "Heather Buchanan",
        "service_type": "Garage Door Services",
        "tech_name": "James R.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Benjamin Estrada",
        "service_type": "Garage Door Installation",
        "tech_name": "Nicole L.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Amanda Giles",
        "service_type": "Garage Door Opener",
        "tech_name": "Eric W.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-14",
        "review_rate": 5.0,
        "customer_name": "John Marks",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Christina B.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-12",
        "review_rate": 5.0,
        "customer_name": "Jennifer Randall",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Joseph C.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.8,
        "customer_name": "Kim Waller",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jessica H.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.8,
        "customer_name": "Xavier Lee",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Nicole B.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.9,
        "customer_name": "Madison Schmidt",
        "service_type": "Garage Door Repair",
        "tech_name": "Michael W.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-12",
        "review_rate": 5.0,
        "customer_name": "Victoria Jones",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Dylan F.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-08",
        "review_rate": 5.0,
        "customer_name": "Desiree Young",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Holly G.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.8,
        "customer_name": "Kathy Smith",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Bryan P.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.9,
        "customer_name": "Jesse Coleman",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jason B.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.8,
        "customer_name": "Justin Chen",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Timothy W.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "William Boyer",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Heather M.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.8,
        "customer_name": "Adam Griffith",
        "service_type": "Garage Door Repair",
        "tech_name": "Alison R.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.8,
        "customer_name": "Brooke Black",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Anthony M.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.8,
        "customer_name": "Crystal Clark",
        "service_type": "Garage Door Services",
        "tech_name": "Jeffrey S.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-09",
        "review_rate": 5.0,
        "customer_name": "Deanna Garcia",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Connie W.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Robin Ross",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Sierra W.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.8,
        "customer_name": "Christina Jacobs",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Lydia M.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.8,
        "customer_name": "Jake Brady",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Dennis A.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-04",
        "review_rate": 5.0,
        "customer_name": "Edward Nash",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jessica D.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.8,
        "customer_name": "Alex Davis",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Stephanie R.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.8,
        "customer_name": "Paula Vaughn",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Lisa R.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-25",
        "review_rate": 5.0,
        "customer_name": "Sara Taylor",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Lindsay D.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.8,
        "customer_name": "Raymond Arnold",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Tammy C.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Roy Nixon",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kristin H.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-06",
        "review_rate": 5.0,
        "customer_name": "Dustin Obrien",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Lisa M.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "John Snyder",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jessica J.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Troy Allen",
        "service_type": "Garage Door Services",
        "tech_name": "Michael S.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Miranda Thompson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Linda H.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.9,
        "customer_name": "Sara Espinoza",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jennifer M.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-07",
        "review_rate": 5.0,
        "customer_name": "Brad Phillips",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kathleen H.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-02",
        "review_rate": 5.0,
        "customer_name": "Cheryl Smith",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Crystal J.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.8,
        "customer_name": "Mrs. Marissa Sanchez",
        "service_type": "Garage Door Installation",
        "tech_name": "Christopher R.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Gabriel Weaver",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Adam A.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Robin Johnson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Paul M.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-06",
        "review_rate": 5.0,
        "customer_name": "Christopher Contreras",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Ronald W.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "James Roberts",
        "service_type": "Garage Door Opener",
        "tech_name": "Gregory P.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Virginia Ritter",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Eric B.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Christine Brady",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jeffrey C.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-18",
        "review_rate": 5.0,
        "customer_name": "Neil Nelson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michelle W.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Carlos Nichols",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Mark T.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.8,
        "customer_name": "David Young",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Robert M.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-07",
        "review_rate": 5.0,
        "customer_name": "Katherine Cochran",
        "service_type": "Garage Door Off Track",
        "tech_name": "Anthony A.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Edwin Mann",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Scott R.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Maria Bradford",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Erica H.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-27",
        "review_rate": 5.0,
        "customer_name": "Sarah Nicholson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Tom J.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Christopher Gonzalez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Ryan O.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-22",
        "review_rate": 5.0,
        "customer_name": "Aaron Williams",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "David Z.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-17",
        "review_rate": 5.0,
        "customer_name": "Kelly Ortega",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Marco R.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Christopher Sanders",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "George N.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Walker",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Patricia K.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.8,
        "customer_name": "Jasmin Austin",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Eddie J.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-13",
        "review_rate": 5.0,
        "customer_name": "Alexander Harris",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Matthew B.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Stacy Ramirez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Heather G.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.8,
        "customer_name": "Troy Russell",
        "service_type": "Garage Door Opener",
        "tech_name": "Travis C.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-02",
        "review_rate": 5.0,
        "customer_name": "Amy Gray",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kristi B.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-06",
        "review_rate": 5.0,
        "customer_name": "Brooke Smith",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Justin W.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.8,
        "customer_name": "Briana Moreno",
        "service_type": "Garage Door Services",
        "tech_name": "Heidi S.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Heather Kim",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jaime W.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-09-28",
        "review_rate": 5.0,
        "customer_name": "Kristin Torres",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Robert T.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Kevin Thomas",
        "service_type": "Garage Door Opener",
        "tech_name": "Janet B.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.8,
        "customer_name": "Stephen Newman",
        "service_type": "Garage Door Insulation",
        "tech_name": "Victor W.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "John Patton",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Randy B.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Jason Walker",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Samantha M.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.8,
        "customer_name": "Justin Pace DDS",
        "service_type": "Garage Door Repair",
        "tech_name": "Nathan U.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Amber Walker",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Gabrielle W.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.8,
        "customer_name": "Tammy Baird",
        "service_type": "Garage Door Opener",
        "tech_name": "Thomas J.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-12",
        "review_rate": 5.0,
        "customer_name": "Kimberly Mack",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Dawn V.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Ryan Johnson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Andrea W.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Christine Lamb",
        "service_type": "Garage Door Insulation",
        "tech_name": "Rebecca S.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Rachael Pierce",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Samuel O.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Kenneth Rose",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Taylor R.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Michelle Buchanan",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Michael E.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.8,
        "customer_name": "Jennifer May",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Shawn H.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.8,
        "customer_name": "Tracy Schultz",
        "service_type": "Garage Door Off Track",
        "tech_name": "Adam B.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Rebecca Alexander",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Michael B.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.8,
        "customer_name": "Timothy Hill",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Linda W.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-26",
        "review_rate": 5.0,
        "customer_name": "Walter Brock",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Victoria L.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Brittney Dominguez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jacqueline S.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-21",
        "review_rate": 5.0,
        "customer_name": "Brian Jarvis",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jeffrey G.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Austin Smith",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Christopher W.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.8,
        "customer_name": "Abigail Cox",
        "service_type": "Garage Door Track Repair",
        "tech_name": "David B.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Gregory Stephens",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Tiffany H.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.8,
        "customer_name": "Amanda Lewis",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jaime C.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-26",
        "review_rate": 5.0,
        "customer_name": "Heather Perez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kimberly S.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "Craig Fitzgerald",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Shane A.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-26",
        "review_rate": 5.0,
        "customer_name": "Roy Boyd",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jaclyn B.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Tracy Branch",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kristin F.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Laurie Avila",
        "service_type": "Garage Door Off Track",
        "tech_name": "David H.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Monica Lloyd",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "David W.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-04",
        "review_rate": 5.0,
        "customer_name": "Richard Clayton",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Tim S.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.8,
        "customer_name": "Richard Davis",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Dawn M.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.8,
        "customer_name": "Jordan Ellis",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Daniel M.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.8,
        "customer_name": "Stephen Garrett",
        "service_type": "Garage Door Maintenance",
        "tech_name": "John B.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Mr. Johnathan Boyd PhD",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Julie B.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.8,
        "customer_name": "Lydia Miller",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kim L.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-26",
        "review_rate": 5.0,
        "customer_name": "Corey Kirk",
        "service_type": "Garage Door Installation",
        "tech_name": "Angela L.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.8,
        "customer_name": "Julian Manning",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Nicholas J.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Roger Kerr",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Eugene T.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.8,
        "customer_name": "Brian Johnson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Margaret W.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Jason Martin",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Veronica H.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-30",
        "review_rate": 5.0,
        "customer_name": "Mitchell Waller",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Emily P.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-20",
        "review_rate": 5.0,
        "customer_name": "Michelle Torres",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Angela S.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-17",
        "review_rate": 5.0,
        "customer_name": "Anthony Lozano DDS",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Richard R.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.8,
        "customer_name": "Dana Brown",
        "service_type": "Garage Door Insulation",
        "tech_name": "Laurie H.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-03",
        "review_rate": 5.0,
        "customer_name": "Anthony Kemp",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Aaron E.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Daniel Perez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Eric C.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Nicole Sullivan",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Erik C.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Thomas Brewer",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Nicholas F.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "Oscar Gonzalez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Gabriel F.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Ashley Long",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Ian J.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Melissa Davis",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Justin M.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Sydney Lee",
        "service_type": "Garage Door Installation",
        "tech_name": "Marvin P.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-14",
        "review_rate": 5.0,
        "customer_name": "Ryan Smith",
        "service_type": "Garage Door Services",
        "tech_name": "Sean B.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.8,
        "customer_name": "Philip Davis",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Steven M.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Jonathan Shea",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Timothy M.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Lawrence PhD",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Tammy J.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Dawn Moore",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kevin S.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-20",
        "review_rate": 5.0,
        "customer_name": "Kimberly Vargas",
        "service_type": "Garage Door Repair",
        "tech_name": "Edward R.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Tiffany Wright",
        "service_type": "Garage Door Off Track",
        "tech_name": "Justin S.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Zachary Velazquez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kathy T.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Jennifer Gregory",
        "service_type": "Garage Door Opener",
        "tech_name": "William H.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.9,
        "customer_name": "Corey Keith",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Lisa H.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-07",
        "review_rate": 5.0,
        "customer_name": "Kevin Freeman",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jason B.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Bryan Fields",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Ashley J.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.9,
        "customer_name": "John Cruz",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Christina H.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Katherine Price",
        "service_type": "Garage Door Off Track",
        "tech_name": "Thomas S.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-14",
        "review_rate": 5.0,
        "customer_name": "Jorge Wright",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Michael G.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.8,
        "customer_name": "Frank Gross",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kim G.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-30",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Garcia",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Charles A.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-11",
        "review_rate": 5.0,
        "customer_name": "Angela Webster",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Lucas C.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-02",
        "review_rate": 5.0,
        "customer_name": "Ashley Campbell",
        "service_type": "Garage Door Services",
        "tech_name": "Kyle S.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Keith Green",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Adam H.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-20",
        "review_rate": 5.0,
        "customer_name": "Jennifer Oliver",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Christopher M.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.8,
        "customer_name": "April Solomon",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jose M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-24",
        "review_rate": 5.0,
        "customer_name": "Amy Fisher",
        "service_type": "Garage Door Repair",
        "tech_name": "Preston W.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Devin Martinez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Heather P.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.8,
        "customer_name": "David Ali",
        "service_type": "Garage Door Repair",
        "tech_name": "Paul C.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Karina Wiley",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Morgan O.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Shelby Clark",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kristen S.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-11",
        "review_rate": 5.0,
        "customer_name": "Ashlee Roberts",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Carlos M.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.8,
        "customer_name": "Holly Cruz",
        "service_type": "Garage Door Services",
        "tech_name": "Samuel R.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-11",
        "review_rate": 5.0,
        "customer_name": "Michael Stewart",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Vanessa F.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.9,
        "customer_name": "Leslie Cain",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Ethan S.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Kelly Thomas",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jay T.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Samuel Aguilar",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jamie T.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.8,
        "customer_name": "Randy Shepherd",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Emily J.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-21",
        "review_rate": 5.0,
        "customer_name": "Scott Ruiz",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Melinda B.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-13",
        "review_rate": 5.0,
        "customer_name": "Jennifer Martin",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Timothy C.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.8,
        "customer_name": "David Webster",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Ruth B.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.8,
        "customer_name": "Kristen Riley",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Danielle M.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Steven Garcia",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Holly G.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Mr. Eric Scott",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Thomas J.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.8,
        "customer_name": "Frank Lewis",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Christopher W.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.8,
        "customer_name": "Christopher Patterson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Nathan P.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Nicole Rodriguez",
        "service_type": "Garage Door Opener",
        "tech_name": "Randy B.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.8,
        "customer_name": "Charles Vang",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Stephanie P.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-22",
        "review_rate": 5.0,
        "customer_name": "Alan Ramirez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Matthew R.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.8,
        "customer_name": "Timothy Davis",
        "service_type": "Garage Door Off Track",
        "tech_name": "Andrew Q.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Joshua Oliver",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Dana D.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.8,
        "customer_name": "Annette Rodriguez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Aaron P.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-11",
        "review_rate": 5.0,
        "customer_name": "Sheryl Vaughan",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Christy B.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.9,
        "customer_name": "Ronald Morrow",
        "service_type": "Garage Door Opener",
        "tech_name": "Kimberly H.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.8,
        "customer_name": "William Wagner",
        "service_type": "Residential Garage Door Services",
        "tech_name": "John M.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Michael Hawkins",
        "service_type": "Garage Door Insulation",
        "tech_name": "Carrie H.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Charles Black",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Tina R.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-29",
        "review_rate": 5.0,
        "customer_name": "Veronica Ashley",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "April B.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Theresa Long",
        "service_type": "Garage Door Maintenance",
        "tech_name": "James H.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-02",
        "review_rate": 5.0,
        "customer_name": "Sheila Armstrong",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Joshua F.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Tracy Atkinson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kimberly S.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.8,
        "customer_name": "Kenneth Davis",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Cody N.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.8,
        "customer_name": "Scott Crosby",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kayla G.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-08",
        "review_rate": 5.0,
        "customer_name": "Curtis Whitehead",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Chelsea Q.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-28",
        "review_rate": 5.0,
        "customer_name": "Nicole Vaughan",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kara H.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Dawn Thomas",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Nicole Y.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-19",
        "review_rate": 5.0,
        "customer_name": "Zachary Boyd",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jose B.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.9,
        "customer_name": "Patricia Holt",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Denise M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "April Lopez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jennifer B.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Alexis Simmons",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Eileen T.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.9,
        "customer_name": "Carrie Sampson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Alexander M.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-26",
        "review_rate": 5.0,
        "customer_name": "Jesus Martin",
        "service_type": "Garage Door Repair",
        "tech_name": "David G.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Jack Bush",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Dustin J.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-17",
        "review_rate": 5.0,
        "customer_name": "Terrance Young",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kaitlyn H.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Taylor Harmon",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Andrea M.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-11",
        "review_rate": 5.0,
        "customer_name": "Robert Long",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Amanda W.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.8,
        "customer_name": "Ashley Smith",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Richard W.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-09",
        "review_rate": 5.0,
        "customer_name": "Sandra Melendez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jennifer M.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Julie Moss",
        "service_type": "Garage Door Installation",
        "tech_name": "Melissa B.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.8,
        "customer_name": "Karen Lozano",
        "service_type": "Garage Door Installation",
        "tech_name": "Luke A.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-16",
        "review_rate": 5.0,
        "customer_name": "Nicholas Wagner",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Joshua C.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-20",
        "review_rate": 5.0,
        "customer_name": "Daniel Porter",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jeffrey L.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.8,
        "customer_name": "John Christian",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Timothy R.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Mindy Rodriguez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Christy P.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Sean Walker",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Gabriela H.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Kelly Dunn",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Timothy B.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Amanda Miller",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Christopher S.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.8,
        "customer_name": "Stephanie Woods",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jerry H.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.8,
        "customer_name": "Michael Hernandez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Timothy J.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "James Weaver",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Samantha H.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "John Fowler",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Ronald A.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "George Villegas",
        "service_type": "Garage Door Installation",
        "tech_name": "Marvin C.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-05",
        "review_rate": 5.0,
        "customer_name": "Tasha Gould",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Valerie O.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Casey Morrow",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "John R.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Michelle Salazar",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael J.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.8,
        "customer_name": "Javier David",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Doris F.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "David Knox",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Todd C.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-09",
        "review_rate": 5.0,
        "customer_name": "Jason Chavez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Garrett C.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "James Hodge",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Wendy D.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-06",
        "review_rate": 5.0,
        "customer_name": "William Bernard",
        "service_type": "Garage Door Repair",
        "tech_name": "Kevin W.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.8,
        "customer_name": "Robert Smith",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kylie B.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Burke",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Amy D.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.8,
        "customer_name": "Stephanie Stevens",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jennifer W.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Bonnie Bauer",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Christopher K.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Ashley Farrell",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Ashley F.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-05",
        "review_rate": 5.0,
        "customer_name": "Thomas Santos",
        "service_type": "Garage Door Repair",
        "tech_name": "Brenda H.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.8,
        "customer_name": "Julie Black",
        "service_type": "Garage Door Services",
        "tech_name": "Jay C.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "John Mills",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Sarah M.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.9,
        "customer_name": "Debbie Stokes",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Thomas M.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-01",
        "review_rate": 5.0,
        "customer_name": "Barbara Nielsen",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Amy L.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-12",
        "review_rate": 5.0,
        "customer_name": "Cassandra Johnson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jennifer C.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-15",
        "review_rate": 5.0,
        "customer_name": "Mrs. Emily Huff",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "David C.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Kelly Gregory",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Nicole D.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.8,
        "customer_name": "Justin Johnson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Megan Y.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.8,
        "customer_name": "Jennifer Wong",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Brandon H.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Jason Johnson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Ronald B.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.8,
        "customer_name": "Michael Miller",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Mary A.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Andrew Mitchell",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Connie W.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.8,
        "customer_name": "Dylan Robbins",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Joshua L.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Ricardo Beard",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Christopher C.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Nicholas Johnson",
        "service_type": "Garage Door Repair",
        "tech_name": "Michelle J.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.8,
        "customer_name": "Jennifer Stout",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Victor B.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Linda Chen",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Debbie O.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.8,
        "customer_name": "Chelsey Shelton",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Donna J.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-03",
        "review_rate": 5.0,
        "customer_name": "Blake May",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Ashley K.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-18",
        "review_rate": 5.0,
        "customer_name": "Alyssa Ramirez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Ronald R.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.8,
        "customer_name": "Steve Carroll",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Tyler M.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-21",
        "review_rate": 5.0,
        "customer_name": "Deanna Gonzalez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Monica J.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Curtis Ruiz",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Tiffany L.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.8,
        "customer_name": "Wendy Wong",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Dawn L.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-06",
        "review_rate": 5.0,
        "customer_name": "Beverly Young",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Lynn H.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Cohen",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Laura H.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Ryan Davis",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jillian F.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.9,
        "customer_name": "Terry Santiago",
        "service_type": "Garage Door Insulation",
        "tech_name": "Ricky G.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.8,
        "customer_name": "Kenneth Rogers",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Christopher P.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.8,
        "customer_name": "Hannah Kline",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Sydney M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Ms. Brittany Goodwin",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Nicole C.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.8,
        "customer_name": "Michael Wallace",
        "service_type": "Garage Door Opener",
        "tech_name": "Mary H.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-31",
        "review_rate": 5.0,
        "customer_name": "Sylvia Ward",
        "service_type": "Residential Garage Door Services",
        "tech_name": "John L.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.8,
        "customer_name": "John Cline",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Douglas H.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Nicholas Cooper",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Melissa F.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Lawrence Woods",
        "service_type": "Garage Door Installation",
        "tech_name": "Gloria M.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.8,
        "customer_name": "George Cook",
        "service_type": "Garage Door Opener",
        "tech_name": "Rhonda D.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.8,
        "customer_name": "Jill Armstrong",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Mary L.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.8,
        "customer_name": "Kimberly Green",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Lori W.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Sherri Durham",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Sara O.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.8,
        "customer_name": "Sabrina Curry",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "John T.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Taylor Peterson",
        "service_type": "Garage Door Repair",
        "tech_name": "James R.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Mr. John Salinas PhD",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Gavin R.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.8,
        "customer_name": "Robin White",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jessica M.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Benjamin Jones",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Sharon H.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-08",
        "review_rate": 5.0,
        "customer_name": "Holly Nelson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Paul E.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-24",
        "review_rate": 5.0,
        "customer_name": "Jessica Campbell",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Gabriel R.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.8,
        "customer_name": "Natalie Hodge",
        "service_type": "Garage Door Maintenance",
        "tech_name": "William N.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-21",
        "review_rate": 5.0,
        "customer_name": "John Ruiz",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kimberly H.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.8,
        "customer_name": "Tiffany Taylor",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Brianna B.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-17",
        "review_rate": 5.0,
        "customer_name": "Edwin King",
        "service_type": "Garage Door Installation",
        "tech_name": "Erica M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Sharon Holmes",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Charles R.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Ashley Church",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "David J.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-31",
        "review_rate": 5.0,
        "customer_name": "Michael Fisher",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Erika T.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-16",
        "review_rate": 5.0,
        "customer_name": "Mary Smith",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Robert W.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Jermaine Buchanan",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Aaron B.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Nicole Kim",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jacob M.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Casey Ortega",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Eric L.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-17",
        "review_rate": 5.0,
        "customer_name": "Kelly Perry",
        "service_type": "Garage Door Services",
        "tech_name": "John O.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Caitlin Robinson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Barbara N.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Jeff Johnson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Dylan C.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Kenneth Johnson",
        "service_type": "Garage Door Opener",
        "tech_name": "Tyler M.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.8,
        "customer_name": "Stephanie Fuentes",
        "service_type": "Garage Door Installation",
        "tech_name": "Glenda D.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Diana Perez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jennifer D.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Megan Collins",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jason F.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.8,
        "customer_name": "Christina Farmer",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Robert P.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Christine Pham",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Cheryl F.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.8,
        "customer_name": "Tyler Valencia",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Ralph J.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Daniel Curry",
        "service_type": "Garage Door Off Track",
        "tech_name": "Danielle R.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Jordan Montgomery",
        "service_type": "Garage Door Off Track",
        "tech_name": "Ian C.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.8,
        "customer_name": "Lisa Morgan",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Robin F.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Theresa Baldwin",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Melissa G.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.8,
        "customer_name": "Javier Payne",
        "service_type": "Garage Door Repair",
        "tech_name": "Charlene W.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Laura Conley",
        "service_type": "Garage Door Opener",
        "tech_name": "Danny B.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Steven Rocha",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Rachel H.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "John Wood",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Tanner J.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Walter Cherry",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Emma R.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-09",
        "review_rate": 5.0,
        "customer_name": "Kelli Alexander",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Caroline C.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Thomas Woods",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Daniel J.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Tina Davenport",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Shawn W.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Michael Smith",
        "service_type": "Garage Door Installation",
        "tech_name": "Jenny R.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Ebony Oliver",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Nicholas B.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-08",
        "review_rate": 5.0,
        "customer_name": "Rebecca Myers",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Heather S.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Dwayne Walton",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Todd D.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.8,
        "customer_name": "Martin Khan",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Mark R.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-01",
        "review_rate": 5.0,
        "customer_name": "Peggy Nash",
        "service_type": "Garage Door Opener",
        "tech_name": "Adam W.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Kristina Walters",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kristina G.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Jenna Vega",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Mark F.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-15",
        "review_rate": 5.0,
        "customer_name": "Ivan Taylor",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Denise W.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "James Smith",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Melissa M.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Jose Forbes",
        "service_type": "Garage Door Services",
        "tech_name": "Joseph S.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.8,
        "customer_name": "Michael Smith",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Lawrence M.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Brandon King",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Robert G.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Cassandra Nguyen",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Patricia N.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.8,
        "customer_name": "Robert Kline",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "David M.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-21",
        "review_rate": 5.0,
        "customer_name": "Elaine Williams",
        "service_type": "Garage Door Off Track",
        "tech_name": "Bridget M.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Tracy Russell",
        "service_type": "Garage Door Repair",
        "tech_name": "David C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Charles Watson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kyle W.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Karen Rodriguez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Albert L.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "James Wright",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Allen R.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Evans",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Peter M.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-26",
        "review_rate": 5.0,
        "customer_name": "Kimberly Williams",
        "service_type": "Garage Door Opener",
        "tech_name": "Kevin W.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-10",
        "review_rate": 5.0,
        "customer_name": "Alexis Krueger",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Robert G.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-09",
        "review_rate": 5.0,
        "customer_name": "Paul Patterson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Nathan R.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-06",
        "review_rate": 5.0,
        "customer_name": "Cameron Thompson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Samantha T.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.8,
        "customer_name": "Katie Brown",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Brandi J.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.8,
        "customer_name": "John Logan",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Nicholas K.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Levi Holt",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Steven G.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Ashley Phillips",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Nicole E.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Craig Bautista",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Wendy W.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Toni Cook",
        "service_type": "Garage Door Services",
        "tech_name": "Gregory W.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.8,
        "customer_name": "Peter Petersen",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Charles P.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Nathan Ponce",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "John D.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.8,
        "customer_name": "Michael Smith",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Christopher H.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-08",
        "review_rate": 5.0,
        "customer_name": "Amanda Clark",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Mark T.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.9,
        "customer_name": "Barbara Gonzalez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Taylor W.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.8,
        "customer_name": "Heather Griffin",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Travis R.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "Amy Gonzalez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Hunter S.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Barbara Callahan",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Monica K.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Jennifer Calhoun",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Daniel R.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Scott Boyd",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Scott M.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Angela Burnett",
        "service_type": "Garage Door Repair",
        "tech_name": "Shirley G.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Laura Solomon",
        "service_type": "Garage Door Opener",
        "tech_name": "Wyatt W.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Christopher Huff",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Andrea M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Stephen Smith",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Ellen E.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Barry Henry",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Shane V.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "Sarah Martinez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jason W.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Crystal Robinson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kathryn B.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.8,
        "customer_name": "Haley Alvarez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Christopher K.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Erin Page",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kristine E.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Jessica Hernandez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Veronica P.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Jessica Jones",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Lindsay T.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Charles Ibarra",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Dawn M.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.9,
        "customer_name": "Derek Lam",
        "service_type": "Garage Door Services",
        "tech_name": "Angela J.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Sean Cunningham",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "William S.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-29",
        "review_rate": 5.0,
        "customer_name": "Daniel Pearson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Katherine A.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Nicholas Miller",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Barbara R.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.8,
        "customer_name": "Michele Casey",
        "service_type": "Garage Door Services",
        "tech_name": "Brenda S.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-02",
        "review_rate": 5.0,
        "customer_name": "Brittney Hill",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Stuart M.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Brandon Thomas",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Yvonne F.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-25",
        "review_rate": 5.0,
        "customer_name": "Melinda Dunn",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jennifer R.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.8,
        "customer_name": "Sheila Carr",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Anthony A.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "David Brown",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kimberly C.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Evan Morgan",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jesse A.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-28",
        "review_rate": 5.0,
        "customer_name": "Andrew Landry",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Carrie P.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "James Ford",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Natasha W.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-02",
        "review_rate": 5.0,
        "customer_name": "Robert Lee",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Keith J.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-09",
        "review_rate": 5.0,
        "customer_name": "Jonathan Reynolds",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Tracey C.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-25",
        "review_rate": 5.0,
        "customer_name": "Wanda Roberts",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "William M.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Brenda Griffith",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Angela H.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Alexis Ellis",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Todd G.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.8,
        "customer_name": "Maria Shelton",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Emily V.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.8,
        "customer_name": "Maureen Jacobs",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Tonya W.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-19",
        "review_rate": 5.0,
        "customer_name": "Robert Jordan",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Rebecca C.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Mr. Eddie Adams",
        "service_type": "Garage Door Services",
        "tech_name": "Julie F.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Victoria Taylor",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Leslie R.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Scott Fowler",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Antonio M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.8,
        "customer_name": "Seth Diaz",
        "service_type": "Garage Door Opener",
        "tech_name": "Jennifer B.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-15",
        "review_rate": 5.0,
        "customer_name": "Christian Miller",
        "service_type": "Garage Door Insulation",
        "tech_name": "Andrea W.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-21",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Castro",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Eric C.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Sherry Butler",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Lori C.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.8,
        "customer_name": "Gabriella Williams",
        "service_type": "Garage Door Insulation",
        "tech_name": "Brandi P.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-09",
        "review_rate": 5.0,
        "customer_name": "Donald Johnson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Joseph M.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Shannon Johnson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Heidi V.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Nicole Lopez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Dana O.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Vanessa Perez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Robin J.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.8,
        "customer_name": "Shelby Coffey",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Juan C.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Joseph Lee",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michael C.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.8,
        "customer_name": "Darren Ball",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Steven J.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Erik Murray",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Susan J.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.8,
        "customer_name": "Connie Cummings",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Lacey W.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Emma Scott",
        "service_type": "Garage Door Installation",
        "tech_name": "Melinda R.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.8,
        "customer_name": "Martin Barrett",
        "service_type": "Garage Door Installation",
        "tech_name": "Juan M.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.8,
        "customer_name": "Jonathan Ramos",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Scott H.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "David Jenkins",
        "service_type": "Garage Door Installation",
        "tech_name": "Kristina R.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Joseph Rodgers",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Gabriel R.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Kristin Bell",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Valerie B.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Craig Thomas",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Robin F.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-12",
        "review_rate": 5.0,
        "customer_name": "Karen Chavez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Anthony A.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "James Hurst",
        "service_type": "Garage Door Services",
        "tech_name": "Dylan L.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.8,
        "customer_name": "Eric Jones",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Paul R.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Gina Chase",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Derek H.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-06",
        "review_rate": 5.0,
        "customer_name": "Autumn Mejia",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Dennis B.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.8,
        "customer_name": "Michael Nelson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kristi Z.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-15",
        "review_rate": 5.0,
        "customer_name": "Mary Rojas",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jeffrey G.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "William Preston",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Timothy A.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-18",
        "review_rate": 5.0,
        "customer_name": "Zachary Frazier",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Eric B.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.8,
        "customer_name": "Michael Perez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Dawn W.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-12",
        "review_rate": 5.0,
        "customer_name": "Jodi Miller",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Fred J.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.9,
        "customer_name": "Amanda Eaton",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Sierra J.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Kathryn Smith",
        "service_type": "Garage Door Insulation",
        "tech_name": "Veronica R.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.8,
        "customer_name": "Kristen Gutierrez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Catherine H.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.8,
        "customer_name": "Carol Bush",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Lindsay W.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Joseph Glenn",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Scott B.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Tammy Swanson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Bryan H.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.8,
        "customer_name": "Karen Turner",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Joshua T.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.8,
        "customer_name": "Krystal Romero",
        "service_type": "Garage Door Installation",
        "tech_name": "Shannon H.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.8,
        "customer_name": "Steven Thomas",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jacob O.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-24",
        "review_rate": 5.0,
        "customer_name": "Samuel Harris",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Thomas C.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "James Fuller",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Caitlin S.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Travis Mclaughlin",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Joyce R.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-20",
        "review_rate": 5.0,
        "customer_name": "Tracy Smith",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Alfred B.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Terri Bond",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jeremy R.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Jeremiah Robinson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Vicki S.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.8,
        "customer_name": "Shannon Webb",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jessica H.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Miranda Green",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Hannah B.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Michael Dorsey",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Lisa O.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Jason Callahan",
        "service_type": "Garage Door Insulation",
        "tech_name": "Tina V.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "Curtis Campbell",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Danny W.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-16",
        "review_rate": 5.0,
        "customer_name": "Gloria Frank",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Hayley R.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.8,
        "customer_name": "Brandy Payne",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kelly W.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-20",
        "review_rate": 5.0,
        "customer_name": "Jacob Mitchell",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Mark P.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.8,
        "customer_name": "James Jacobs",
        "service_type": "Garage Door Repair",
        "tech_name": "Amy N.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "James Acosta",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Rachel A.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.8,
        "customer_name": "Christopher Lee MD",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Olivia A.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Jennifer Bradley",
        "service_type": "Garage Door Opener",
        "tech_name": "Robert G.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "James Webb",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Paula J.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-17",
        "review_rate": 5.0,
        "customer_name": "Larry Colon",
        "service_type": "Garage Door Insulation",
        "tech_name": "Debra K.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Julia Bauer",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Victor S.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.9,
        "customer_name": "Monica Collins",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Sharon M.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "Scott Riley",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "David O.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-09",
        "review_rate": 5.0,
        "customer_name": "Joshua Calhoun",
        "service_type": "Garage Door Off Track",
        "tech_name": "James R.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Audrey Powers",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Amanda C.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-23",
        "review_rate": 5.0,
        "customer_name": "Michael Reeves",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Alicia P.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Bradley Hurley",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kevin A.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-05",
        "review_rate": 5.0,
        "customer_name": "Zachary Hartman",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Michael C.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.8,
        "customer_name": "Amber Martin",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Teresa L.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Ashley Perez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Joseph M.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-19",
        "review_rate": 5.0,
        "customer_name": "Jennifer Becker",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jacqueline W.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.9,
        "customer_name": "Nicole Henderson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Patrick S.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Eugene Montes",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Timothy B.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Christy Adams",
        "service_type": "Garage Door Services",
        "tech_name": "Kevin M.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Jesse Gillespie",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Donna G.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.8,
        "customer_name": "Christina Ho",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jasmine S.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-08",
        "review_rate": 5.0,
        "customer_name": "Matthew Montgomery",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Amanda H.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Mary West",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Ryan C.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-18",
        "review_rate": 5.0,
        "customer_name": "Alison Hebert",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Larry T.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.8,
        "customer_name": "Mike West",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Crystal R.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.8,
        "customer_name": "Julie Velez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Timothy S.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Omar Reed",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Yolanda H.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-06",
        "review_rate": 5.0,
        "customer_name": "Patricia Roach",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kevin B.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-24",
        "review_rate": 5.0,
        "customer_name": "Melissa Wallace",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "John S.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "David Ortiz",
        "service_type": "Garage Door Opener",
        "tech_name": "Daniel N.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Erin Woods",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Cameron H.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Carol Evans",
        "service_type": "Garage Door Insulation",
        "tech_name": "Tommy J.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-26",
        "review_rate": 5.0,
        "customer_name": "Beverly Wheeler",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "James T.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-24",
        "review_rate": 5.0,
        "customer_name": "Mr. Brandon Cole",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Melissa D.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Laura Herman",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Brian E.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-22",
        "review_rate": 5.0,
        "customer_name": "Erica Sullivan",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Alison L.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-20",
        "review_rate": 5.0,
        "customer_name": "Brad Jordan",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Chad J.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-26",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Rowland",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jennifer P.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.8,
        "customer_name": "Jay Murphy",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kimberly J.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Dan Thomas",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Carl G.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-04",
        "review_rate": 5.0,
        "customer_name": "Aaron Moore",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Tiffany H.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Renee Welch",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Danny L.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Anthony Sanford",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Paige G.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Dominic Edwards",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Julie C.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.8,
        "customer_name": "Michele Rios",
        "service_type": "Garage Door Services",
        "tech_name": "Kelli M.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Melanie Turner PhD",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Joseph O.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Tammy Weber",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Crystal C.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Nancy Wood",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Melissa H.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-15",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Brown",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kim B.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Diamond Norris",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Adam M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Miguel Ryan",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Lacey C.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Robin Klein",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Edwin O.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "Keith Cruz",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Nicole K.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Shirley Mendoza",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jennifer B.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.8,
        "customer_name": "Thomas Hart",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Garrett R.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.8,
        "customer_name": "Frank Williams",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Joseph B.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-07",
        "review_rate": 5.0,
        "customer_name": "Christian Scott",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Amber B.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.8,
        "customer_name": "Barbara Gonzalez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christopher F.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.9,
        "customer_name": "Kimberly Barton",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Robert P.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "William Douglas",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Deborah S.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.8,
        "customer_name": "April Beasley",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Paul C.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.8,
        "customer_name": "Wendy Price",
        "service_type": "Garage Door Insulation",
        "tech_name": "David H.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Jean Coleman",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Robert B.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.8,
        "customer_name": "Tyler Hoover",
        "service_type": "Garage Door Services",
        "tech_name": "Linda A.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-19",
        "review_rate": 5.0,
        "customer_name": "Erica Mclaughlin",
        "service_type": "Garage Door Opener",
        "tech_name": "Tiffany K.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Rebecca Mcintyre",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Michele W.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.8,
        "customer_name": "David Stevens",
        "service_type": "Garage Door Track Repair",
        "tech_name": "David C.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Nathan Ryan",
        "service_type": "Garage Door Off Track",
        "tech_name": "Justin B.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Crystal Baker",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Brian H.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.8,
        "customer_name": "Aaron Sutton",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Michael W.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Tanya Wagner",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Maria B.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.8,
        "customer_name": "Steven Lee",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Brittany F.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.8,
        "customer_name": "William Duran",
        "service_type": "Garage Door Installation",
        "tech_name": "Richard B.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Molly Chambers",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Meghan O.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Taylor",
        "service_type": "Garage Door Installation",
        "tech_name": "Brian R.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Christina Johnson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Katrina G.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Sherry Lewis",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jon A.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Frank Ward",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Beth M.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.8,
        "customer_name": "Morgan Page",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Elizabeth M.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-22",
        "review_rate": 5.0,
        "customer_name": "Pamela Jones",
        "service_type": "Garage Door Insulation",
        "tech_name": "Katelyn C.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Steven Miranda",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Sharon W.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-02",
        "review_rate": 5.0,
        "customer_name": "Kim Davis",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kathy R.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.8,
        "customer_name": "Meredith Branch",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jasmine C.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-25",
        "review_rate": 5.0,
        "customer_name": "Jennifer Contreras",
        "service_type": "Custom Garage Door Design",
        "tech_name": "David R.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-01",
        "review_rate": 5.0,
        "customer_name": "Brooke Tucker",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jeremy G.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Kyle Donovan DDS",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Nancy Y.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Garcia",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Juan S.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.9,
        "customer_name": "Kyle George",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kaitlyn M.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-23",
        "review_rate": 5.0,
        "customer_name": "Amy Jenkins",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Rodney S.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.8,
        "customer_name": "Jesse Walter",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Victor G.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Christopher Robinson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Juan F.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Benjamin Walker",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Paul C.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Benjamin Peterson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Andrew J.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.8,
        "customer_name": "Melanie Sims",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Joseph H.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "David Collins",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Aaron D.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Sydney Parrish",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Darrell G.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.8,
        "customer_name": "April Torres",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Tim S.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Jessica Roberts",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "David S.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-18",
        "review_rate": 5.0,
        "customer_name": "Paul Black",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jessica P.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.8,
        "customer_name": "Charles Foster",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Thomas L.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Dustin Sullivan",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Keith S.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Erin Hernandez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Hayden C.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.8,
        "customer_name": "Christopher Johnson",
        "service_type": "Garage Door Services",
        "tech_name": "Kimberly M.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-10",
        "review_rate": 5.0,
        "customer_name": "Tiffany Villanueva",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jonathan W.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-03",
        "review_rate": 5.0,
        "customer_name": "Tammy Rojas",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Marcus A.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Valerie Warren",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Victoria H.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Johnny Palmer",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Tyler M.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.8,
        "customer_name": "Jonathan Davis",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Barbara G.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-11",
        "review_rate": 5.0,
        "customer_name": "Edward Jacobs",
        "service_type": "Garage Door Repair",
        "tech_name": "Wendy P.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Vanessa Martin",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Rachel T.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Misty Williams",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Paula C.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-14",
        "review_rate": 5.0,
        "customer_name": "Mrs. Brittany Larsen",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Derek S.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Dawn Hernandez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Michael H.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-26",
        "review_rate": 5.0,
        "customer_name": "Donald Morgan",
        "service_type": "Garage Door Services",
        "tech_name": "Nicholas B.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-16",
        "review_rate": 5.0,
        "customer_name": "Kelly Bright",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Todd S.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-30",
        "review_rate": 5.0,
        "customer_name": "Mr. Earl Raymond",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kathy R.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Julie Gonzalez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jessica M.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Stacy Sharp",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Nicholas P.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Courtney Long",
        "service_type": "Garage Door Repair",
        "tech_name": "Wanda S.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-23",
        "review_rate": 5.0,
        "customer_name": "Hannah Baker",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jennifer N.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.8,
        "customer_name": "Catherine Henderson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Patrick W.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Angela Raymond",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kenneth M.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-13",
        "review_rate": 5.0,
        "customer_name": "Crystal Sullivan",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Caroline H.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-15",
        "review_rate": 5.0,
        "customer_name": "Shannon Howard",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Pedro O.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Frank Crawford PhD",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Carla T.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Eric Miller",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jessica A.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Jennifer Brown",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Blake R.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Ralph Rodriguez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Joseph G.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.9,
        "customer_name": "Olivia Leonard",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Lindsay L.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-01",
        "review_rate": 5.0,
        "customer_name": "Carrie Castillo",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Catherine W.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Jennifer Turner",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Pamela G.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Paul Singh",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Diane J.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-28",
        "review_rate": 5.0,
        "customer_name": "Lauren Chan",
        "service_type": "Garage Door Repair",
        "tech_name": "Amanda M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Justin Harvey",
        "service_type": "Garage Door Installation",
        "tech_name": "Russell G.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.8,
        "customer_name": "John Hunter",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Angela H.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Michelle Allen",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Virginia B.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-05",
        "review_rate": 5.0,
        "customer_name": "Matthew Smith",
        "service_type": "Garage Door Installation",
        "tech_name": "Kayla H.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.8,
        "customer_name": "Herbert Dominguez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Chad P.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.9,
        "customer_name": "Emily Carlson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Emma C.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.8,
        "customer_name": "Ryan Smith",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Brittany J.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Diana Carroll",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Holly S.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-14",
        "review_rate": 5.0,
        "customer_name": "Mary Nelson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Samuel A.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Suzanne Thompson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Karl S.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-24",
        "review_rate": 5.0,
        "customer_name": "Jasmine Peters",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Edward R.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-19",
        "review_rate": 5.0,
        "customer_name": "Valerie Potts",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Daniel T.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.8,
        "customer_name": "Lucas Neal",
        "service_type": "Garage Door Opener",
        "tech_name": "John L.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "Jennifer Willis",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Darren W.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.8,
        "customer_name": "Sheila King",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "William V.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-23",
        "review_rate": 5.0,
        "customer_name": "Richard Johnson",
        "service_type": "Garage Door Installation",
        "tech_name": "Jason W.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.9,
        "customer_name": "Kelsey Fletcher",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Samantha M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "Kyle Martinez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Abigail T.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-04",
        "review_rate": 5.0,
        "customer_name": "Carlos Snow",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Bethany F.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.8,
        "customer_name": "Kimberly Johnson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Samuel T.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.8,
        "customer_name": "Russell Wallace",
        "service_type": "Garage Door Installation",
        "tech_name": "Sylvia R.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-13",
        "review_rate": 5.0,
        "customer_name": "Jane Bishop",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Natasha M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.8,
        "customer_name": "Kevin Chavez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kendra J.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.8,
        "customer_name": "Michael Spears",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kevin J.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.8,
        "customer_name": "Caitlin Hudson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Marie D.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Donald Munoz Jr.",
        "service_type": "Garage Door Insulation",
        "tech_name": "Juan W.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Robert Farrell",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Dylan H.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.8,
        "customer_name": "John Chandler",
        "service_type": "Garage Door Services",
        "tech_name": "Jeffrey F.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Theresa Lane",
        "service_type": "Garage Door Opener",
        "tech_name": "Christopher G.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Melvin Bailey",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Casey G.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Karen Burns",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Matthew R.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Michael Walker",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Robin A.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-27",
        "review_rate": 5.0,
        "customer_name": "Molly Hayes",
        "service_type": "Garage Door Insulation",
        "tech_name": "Brooke W.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.8,
        "customer_name": "Erica Powell",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Erica P.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Erin Coleman",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Sherry S.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.8,
        "customer_name": "Ashley Lewis",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Tiffany B.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Heather Edwards",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jeremy P.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Christopher Flores",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kendra R.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-07",
        "review_rate": 5.0,
        "customer_name": "Abigail Smith",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jimmy R.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Monica Costa",
        "service_type": "Garage Door Insulation",
        "tech_name": "Robin H.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.9,
        "customer_name": "Karen Hernandez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Michael A.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.8,
        "customer_name": "Corey Patton",
        "service_type": "Garage Door Insulation",
        "tech_name": "Alexander K.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    }
]
export default LocationReviewsData;
