import React from "react";
import './Faq.scss';
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from '../../Images/Logo.png'
import closeMenu from '../../Images/closeMenu.png'
import telephone from '../../Images/telephone.png'
import book from '../../Images/book.png'
// images
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"


export default function Faq({ Blogs, FaqList }) {
    const navigate = useNavigate()
    const [faqlistFilter, setfaqlistFilter] = useState(FaqList);
    const [faqlistInputValue, setfaqlistInputValue] = useState("");
    function readingTime(S) {
        const text = S.Text;
        const wpm = 225;
        const words = text.trim().split(/\s+/).length;
        const time = Math.ceil(words / wpm);
        return time
    }
    const getSpecificNumberBlog = () => {
        const number = Math.random() * Blogs.length
        if (number <= (Blogs.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberBlog()
        }
    }
    const [getNumberBlog, setgetNumberBlog] = useState(getSpecificNumberBlog());
    const location = useLocation()
    useEffect(() => {
        // console.log(location.hash);
    }, []);

    return (
        <div className="Faq">
            <Helmet>
                {/* <!-- TITLE -->  */}
                <title>FAQ | Calabasas Garage Door - Your Garage Door Service Questions Answered</title>
                <meta name="title" property="title" content="FAQ | Calabasas Garage Door - Your Garage Door Service Questions Answered" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="FAQ | Calabasas Garage Door - Your Garage Door Service Questions Answered" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* Blog Tags */}
                <meta name="PageType" content="article" />
                <meta name="ContentType" content="article" />
                <meta name="Category" content="article" />
                <meta name="SubCategories" content="article" />
                <meta name="FunnelStage" content="upper" />
                <meta name="Objective" content="education, seo" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.garagedoorcalabasas.org/faq" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Find answers to common questions about garage door services, including installation, repair, and maintenance. Explore our FAQ page for expert advice and insights from Calabasas Garage Door." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Find answers to common questions about garage door services, including installation, repair, and maintenance. Explore our FAQ page for expert advice and insights from Calabasas Garage Door." data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="FAQ | Calabasas Garage Door - Your Garage Door Service Questions Answered" data-react-helmet="true" />
                <meta name="keywords" content="Garage Door, Garage Door Installation, Garage Door Repair, Garage Door Opener, Garage Door Maintenance, Garage Door Services" data-react-helmet="true" />
            </Helmet>

            <div className="FaqMainBanner">
                <h1>Garage Door FAQs</h1>
                <h2>For expert garage door services, reach out to Calabasas Garage Door. Our certified technicians are committed to delivering reliable, high-quality repairs to ensure your garage door remains safe and fully functional.</h2>
                <h5>Categories</h5>
                <div className="FaqMainBannerCategories">
                    <a href="/faq#garage-door-installation">Garage Door Installation</a>
                    <a href="/faq#garage-door-opener">Garage Door Opener</a>
                    <a href="/faq#garage-door-repair">Garage Door Repair</a>
                    <a href="/faq#garage-door-broken-spring">Garage Door Broken Spring</a>
                    <a href="/faq#residential-garage-door-services">Residential Garage Door Services</a>
                    <a href="/faq#garage-door-motor-replacement">Garage Door Motor Replacement</a>
                    <a href="/faq#garage-door-maintenance">Garage Door Maintenance</a>
                </div>
                <a className="BlogAsideCall" href="tel:747-302-2442">CALL 747-302-2442<img src={telephone} alt="Contact Calabasas Garage Door" /></a>
                <a className="BlogAsideCall" href="/book">SCHEDULE ONLINE<img src={book} alt="Schedule Garage Door Service" /></a>
            </div>

            {/* FaqMainList */}
            <div className="FaqMainList">
                {faqlistInputValue ?
                    <div className="FaqList">
                        <div className="FaqListHeaderResults">
                            <h6>Results</h6>
                            <h5 onClick={() => setfaqlistInputValue("")}>Restart Results</h5>
                        </div>
                        {faqlistFilter.filter(a =>
                            a.question.toLocaleLowerCase().includes(faqlistInputValue.toLocaleLowerCase()) ||
                            a.questionPath.toLocaleLowerCase().includes(faqlistInputValue.toLocaleLowerCase())
                        ).map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}

                        {faqlistFilter.length ?
                            <div className="FaqListNoResult">
                                <h1>We're sorry, but your search did not return any results.</h1>
                            </div>
                            : null}

                        <div className="FaqPageHeaderImagePromise">
                            <div className="FaqPageHeaderImagePromiseTop">
                                <div className="FaqPageHeaderImagePromiseTopLeft">
                                    <img src={Logo} alt="Calabasas Garage Door" />
                                    <h2>Calabasas Garage Door Promise</h2>
                                </div>
                                <a href="/apply">More info</a>
                            </div>
                            <p>For professional garage door services, contact Calabasas Garage Door. Our certified technicians are dedicated to providing reliable and high-quality repairs.</p>
                        </div>
                    </div>
                    :
                    <div className="FaqList">
                        <h6 id="garage-door-installation">Garage Door Installation</h6>
                        {FaqList.filter(a => a.category == "Garage Door Installation").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}

                        <h6 id="garage-door-opener">Garage Door Opener</h6>
                        {FaqList.filter(a => a.category == "Garage Door Opener").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}

                        <h6 id="garage-door-repair">Garage Door Repair</h6>
                        {FaqList.filter(a => a.category == "Garage Door Repair").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}

                        <h6 id="garage-door-broken-spring">Garage Door Broken Spring</h6>
                        {FaqList.filter(a => a.category == "Garage Door Broken Spring").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}

                        <h6 id="residential-garage-door-services">Residential Garage Door Services</h6>
                        {FaqList.filter(a => a.category == "Residential Garage Door Services").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}

                        <h6 id="garage-door-motor-replacement">Garage Door Motor Replacement</h6>
                        {FaqList.filter(a => a.category == "Garage Door Motor Replacement").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}

                        <h6 id="garage-door-maintenance">Garage Door Maintenance</h6>
                        {FaqList.filter(a => a.category == "Garage Door Maintenance").map((A, index) =>
                            <article key={index} className="FaqListBox">
                                <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                    <h1>{A.question}</h1>
                                    <h3><span> {A.category}</span></h3>
                                </a>
                            </article>
                        )}
                    </div>
                }
                <aside className="FaqSearch">
                    <h1>Categories</h1>
                    <div className="FaqMainBannerCategories">
                        <a style={location.hash == "#garage-door-installation" ? { "borderBottom": "2px solid #2a1768" } : null} href="/faq#garage-door-installation">Garage Door Installation</a>
                        <a style={location.hash == "#garage-door-opener" ? { "borderBottom": "2px solid #2a1768" } : null} href="/faq#garage-door-opener">Garage Door Opener</a>
                        <a style={location.hash == "#garage-door-repair" ? { "borderBottom": "2px solid #2a1768" } : null} href="/faq#garage-door-repair">Garage Door Repair</a>
                        <a style={location.hash == "#garage-door-broken-spring" ? { "borderBottom": "2px solid #2a1768" } : null} href="/faq#garage-door-broken-spring">Garage Door Broken Spring</a>
                        <a style={location.hash == "#residential-garage-door-services" ? { "borderBottom": "2px solid #2a1768" } : null} href="/faq#residential-garage-door-services">Residential Garage Door Services</a>
                        <a style={location.hash == "#garage-door-motor-replacement" ? { "borderBottom": "2px solid #2a1768" } : null} href="/faq#garage-door-motor-replacement">Garage Door Motor Replacement</a>
                        <a style={location.hash == "#garage-door-maintenance" ? { "borderBottom": "2px solid #2a1768" } : null} href="/faq#garage-door-maintenance">Garage Door Maintenance</a>
                    </div>
                    <h1>Search for a question</h1>
                    <div className="FaqSearchInput">
                        <input
                            value={faqlistInputValue || ''}
                            onFocus={() => {
                                window.screen.width >= 850 ? window.scrollTo(0, 420) : window.scrollTo(0, 570)
                            }}
                            onChange={(e) => {
                                setfaqlistInputValue(e.target.value)
                                window.screen.width >= 850 ? window.scrollTo(0, 420) : window.scrollTo(0, 570)
                            }}
                            type="text"
                            placeholder="Search..."
                        />
                        {faqlistInputValue ?
                            <img onClick={() => setfaqlistInputValue("")} src={closeMenu} alt="Search" />
                            : null}
                    </div>
                </aside>
            </div>

            <div className="BlogsListReview">
                <div className="BlogsListReviewHead">
                    <h1>Related Articles</h1>
                </div>
                <div className="BlogsListReviewList">
                    {Blogs.slice(getNumberBlog, (getNumberBlog + 3)).map((A, index) =>
                        <a href={`/blog/${A.BlogPath.replace(/ /g, "_") + "_" + (A.BlogId - 1)}`} key={index} className="BlogsListReviewBox">
                            <article>
                                <div className="BlogsListReviewBoxLeft">
                                    <img src={A.Photos} alt={A.BlogPath} />
                                </div>
                                <div className="BlogsListReviewBoxRight">
                                    <h1>{A.Title}</h1>
                                    <span>
                                        <h5>{readingTime(A)} MIN READ</h5>
                                        <h4>{A.Date}</h4>
                                    </span>
                                </div>
                            </article>
                        </a>
                    )}
                </div>
            </div>

            <NavigatorPath />
        </div>

    )
}

